import React, { Component } from "react";

import { connect } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

import * as actions from "./Store/action";

import styles from "./ClientList.module.scss";
import cx from "classnames";
import LeftNavBar from '../../../components/atoms/LeftNavBar/LeftNavBar';
import Button from "../../../components/atoms/Button/Button";

class ClientList extends Component {

    componentDidMount() {
        this.props.getClientList();
    }

    getLabel = (label) => {
        if (label === "SPA") {
            return "single page application"
        }
        else if (label === "THIRD_PARTY") {
            return "third party application"
        }
        else return "mobile application"
    }


    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className={cx("col-1 px-0")}>
                        <LeftNavBar />

                    </div>
                    <div className={cx("col-9 pt-4", styles.content)}>
                        <div>

                            <NavLink to={"/clients/add"} >
                                <Button label="add new client" style={{ float: "right" }} /><br />
                            </NavLink>
                        </div>
                        <br />
                        <br />
                        <div className="row">{this.props.clientList.map((list, index) => {
                            let url = "/clients/" + list._id;
                            return (
                                <div className={cx("col-4 px-2 pl-3", styles.clientCardAlign)} key={index}>
                                    <Link to={url} style={{ textDecoration: "none" }}>
                                        <div className={styles.clientCard}>
                                            <div className={styles.cardText}>{list.clientName}</div>
                                            <div className={styles.cardTextSmall}>type: {this.getLabel(list.clientType)}</div>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })}
                        </div>

                    </div>
                </div>

            </React.Fragment>
        )
    }
}
const mapStateToProps = state => {
    return {
        clientList: state.clientMgmt.clientList.clientList,
        getClientListState: state.clientMgmt.clientList.getEmployeeListState,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getClientList: () => dispatch(actions.getClientList()),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientList));