import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router";
import * as actions from "./Store/action";
import { Link } from "react-router-dom";

import Input from "../../../components/atoms/Input/Input";
import LeftNavBar from "../../../components/atoms/LeftNavBar/LeftNavBar";
import DropDown from "../../../components/atoms/DropDown/DropDown";
import Button from "../../../components/atoms/Button/Button";
import MultipleInput from "../../../components/molecules/MultipleInput/MultipleInput";
import arrowLeft from "../../../assets/icons/arrowLeft.svg";
import styles from "./ClientDetails.module.scss";
import cx from 'classnames';
import _ from 'lodash';

class ClientDetails extends Component {

    state = {
        formData: {
            clientName: '',
            redirectURIs: [],
            clientType: '',
            defaultURI: '',
            accessTokenValidity: 10,
            setSessionTimeout: true,
            sessionTimeout: 0,
            setSessionLimit: false,
            maxSessionsAllowed: 0,
        }
    }

    componentDidMount() {
        let differentiator = this.props.match.url.split('/').pop();
        if (differentiator !== "add") {
            this.props.onGetClientDetail(differentiator)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.getClientDetailState !== this.props.getClientDetailState) {
            if (this.props.getClientDetailState === "SUCCESS") {
                this.setState({
                    formData: this.props.clientDetail
                })
            }
        }
    }

    componentWillUnmount() {
        this.props.initState();
    }

    handleInputChange = (event, inputIdentifier, numberValue) => {
        let updatedFormData = {
            ...this.state.formData,
        }
        if(numberValue){
            updatedFormData[inputIdentifier] = parseInt(event.target ? event.target.value : event.value);
        }
        else{
            if (inputIdentifier === "clientType" || inputIdentifier === "setSessionTimeout" || inputIdentifier === "setSessionLimit") {
                updatedFormData[inputIdentifier] = event
            }
            else if (inputIdentifier === "redirectURIs") {
                updatedFormData[inputIdentifier] = event
            }
            else {
                updatedFormData[inputIdentifier] = event.target ? event.target.value : event.value;
            }
        }
        
        this.setState({
            formData: updatedFormData,
            isEdited: true
        });
    };

    handleSubmitForm = (event) => {
        event.preventDefault();
        let formData = _.cloneDeep(this.state.formData);

        let clientId = this.props.clientDetail._id;
        if (clientId) {
            this.props.onPutClientData(formData, clientId);
        } else {
            this.props.onPostClientData(formData);
        }

    }

    handleDeleteClient = (event) => {
        event.preventDefault();
        this.props.onDeleteClient(this.props.clientDetail._id)
    }

    render() {
        return (
            <React.Fragment>
                {this.props.postClientDataState === "SUCCESS" ? <Redirect to={"/clients/" + this.props.clientId} /> : null}
                {this.props.deleteClientState === "SUCCESS" ? <Redirect to={"/clients"} /> : null}

                <div className="row">
                    <div className="col-1 px-0">
                        <LeftNavBar />
                    </div>

                    <div className="col-8 mt-4 mx-3">
                        <Link to={"/clients"} className={styles.back}><img src={arrowLeft} alt="arrow" className="mr-2" />clients</Link>
                        <div className={styles.pageHead}>client details</div>
                        <br />
                        <div className={styles.form}>
                            <div >
                                <form>
                                    <div className={styles.alignTabs}>
                                        <Input
                                            name="clientName"
                                            className="col-5 pr-3"
                                            label="client name"
                                            type="text"
                                            placeholder="client name"
                                            changed={(event) => this.handleInputChange(event, 'clientName')}
                                            value={this.state.formData.clientName}
                                        />

                                        {this.props.clientDetail.clientKey ?
                                            <div className="mt-3 ml-5">
                                                <span className={styles.label}>client key</span>
                                                <span className={styles.KeyBg}>{this.props.clientDetail.clientKey}</span>
                                            </div>
                                            : null}
                                    </div>
                                    <div className="row px-0">
                                        <div className="ml-4 mt-3">
                                            <DropDown

                                                Options={[{ "option": "SPA", "optionLabel": "single page application" },
                                                { "option": "MOBILE", "optionLabel": "mobile application" },
                                                { "option": "THIRD_PARTY", "optionLabel": "third party" }
                                                ]}
                                                changed={(value) => this.handleInputChange(value, 'clientType')}
                                                value={this.state.formData.clientType}
                                                label="client type"
                                            />
                                        </div>

                                        <Input
                                            name="defaultURI"
                                            className={cx("col-5 pt-3", styles.widthFix)}
                                            label="default URI"
                                            type="text"
                                            placeholder="default URI"
                                            changed={(event) => this.handleInputChange(event, 'defaultURI')}
                                            value={this.state.formData.defaultURI}

                                        />
                                    </div>

                                    <MultipleInput
                                        changed={(value) => this.handleInputChange(value, 'redirectURIs')}
                                        UriArr={this.state.formData.redirectURIs}
                                        name="redirectURIs"
                                        label="redirect URI"
                                        placeholder="redirect URI"
                                    />
                                    <Input
                                        name="accessTokenValidity"
                                        className={cx("col-4 pt-3 pl-3")}
                                        label="access token validity (minutes)"
                                        type="number"
                                        placeholder="enter access token validity in minutes"
                                        changed={(event) => this.handleInputChange(event, 'accessTokenValidity', 'int')}
                                        value={this.state.formData.accessTokenValidity}
                                    />
                                    <div className="row mx-0 px-0">
                                        <div className="ml-3 mt-3 mr-4">
                                            <DropDown
                                                Options={[{
                                                    "option": true, "optionLabel": "yes"
                                                },
                                                { "option": false, "optionLabel": "no" },
                                                ]}
                                                changed={(value) => this.handleInputChange(value, 'setSessionTimeout')}
                                                value={this.state.formData.setSessionTimeout}
                                                label="set session timeout"
                                            />
                                        </div>
                                        <Input
                                            name="sessionTimeout"
                                            className={cx("col-4 pt-3 pl-4")}
                                            label="session timeout (minutes)"
                                            type="number"
                                            placeholder="enter session timeout in minutes"
                                            changed={(event) => this.handleInputChange(event, 'sessionTimeout', 'int')}
                                            value={this.state.formData.sessionTimeout}
                                        />
                                    </div>
                                    <div className="row mx-0 px-0">
                                        <div className="ml-3 mt-3 mr-4">
                                            <DropDown
                                                Options={[{
                                                    "option": true, "optionLabel": "yes"
                                                },
                                                { "option": false, "optionLabel": "no" },
                                                ]}
                                                changed={(value) => this.handleInputChange(value, 'setSessionLimit')}
                                                value={this.state.formData.setSessionLimit}
                                                label="set session limit"
                                            />
                                        </div>
                                        <Input
                                            name="maxSessionsAllowed"
                                            className={cx("col-4 pt-3 pl-4")}
                                            label="maximum session allowed"
                                            type="number"
                                            placeholder="enter maximum session allowed"
                                            changed={(event) => this.handleInputChange(event, 'maxSessionsAllowed', 'int')}
                                            value={this.state.formData.maxSessionsAllowed}
                                        />
                                    </div>
                                </form>
                                <div className={styles.save}>
                                    <Button
                                        type="delete"
                                        clickHandler={(event) => this.handleDeleteClient(event)}
                                        label="delete" />

                                    &nbsp;&nbsp;

                                    <Button label="save" className={"ml-auto"} clickHandler={(event) => this.handleSubmitForm(event)} /><br />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        clientId: state.clientMgmt.clientDetails.clientId,
        postClientDataState: state.clientMgmt.clientDetails.postClientDataState,
        getClientDetailState: state.clientMgmt.clientDetails.getClientDetailState,
        clientDetail: state.clientMgmt.clientDetails.clientDetail,
        deleteClientState: state.clientMgmt.clientDetails.deleteClientState
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onPostClientData: (data) => dispatch(actions.postClientData(data)),
        onPutClientData: (data, id) => dispatch(actions.putClientData(data, id)),
        onGetClientDetail: (url) => dispatch(actions.getClientDetail(url)),
        onDeleteClient: (id) => dispatch(actions.deleteClient(id)),
        initState: () => dispatch(actions.initState())
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientDetails));