import React, { Component } from 'react';
import { withRouter } from "react-router";
import { Route , Switch} from "react-router-dom";

import ClientList from "./ClientList/ClientList";
import ClientHome from "./ClientHome/ClientHome";
import ClientDetails from "./ClientDetails/ClientDetails";
import Resources from "./../Resources/Resources";
import Users from "./../Users/Users";
import Activity from '../Activity/Activity';
import UserDetails from "../Users/UserDetails/UserDetails";
import ResourceDetails from "../Resources/ResourceDetails/ResourceDetails";

class ClientMgmt extends Component {
    render(){
        return(
            <div>
                <Switch>
                    <Route path="/" exact component={ClientHome} />

                    <Route path="/clients" exact component={ClientList} />
                    <Route path="/clients/add" component={ClientDetails} />
                    <Route path="/clients/:clientId" component={ClientDetails} />

                    <Route path="/users" exact component={Users} />
                    <Route path="/users/:userId" component={UserDetails} />

                    <Route path="/activity" exact component={Activity} />
                    
                    <Route path="/resource" exact component={Resources} />
                    <Route path="/resource/add" exact component={ResourceDetails} />
                    <Route path="/resource/:resourceId" exact component={ResourceDetails} />

                </Switch>
            </div>
        )
    }
}

export default withRouter(ClientMgmt);