import React from 'react';
import './App.module.scss';

import { connect } from "react-redux";
import Home from '../src/containers/Home/Home';
import { AuthConsumer } from './services/authContext';
import HandleLogin from './services/Auth/HandleLogin';
import ErrorLogin from "./components/molecules/ErrorHandler/LoginErrorPage/LoginErrorPage";
import _ from "lodash";

function App(props) {
  return (
    <AuthConsumer>
      {
        ({ authenticated, user }) => {
          if (authenticated && !_.isEmpty(user)) {
            return (
              <div className="App">
                {
                  props.auth === "SUPER_ADMIN"?
                  <header className="App-header">
                  <Home />
                </header>
                : <ErrorLogin />
                }
                
              </div>
            )
          }
          else{
            return ( <HandleLogin /> )
          }
        }
      }
    </AuthConsumer>
  );
}
const mapStateToProps = state => {
  return {
      auth: state.auth.user.userGroup
  };
};

export default connect(mapStateToProps, null)(App);
