import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import * as actions from "./Store/action";
import isEmpty from 'lodash/isEmpty';
import styles from "./LoginPage.module.scss";
import cx from "classnames";
import BPlogo from "../../assets/icons/betterPlaceNewLogo.svg";
import refreshIcon from "../../assets/icons/Resend.svg";
import editIcon from "../../assets/icons/Edit.svg";
import dots from "../../assets/icons/dots.svg";
import warning from "../../assets/icons/warning.svg"
import PasswordInput from '../../components/atoms/PasswordInput/PasswordInput';
import OtpInput from 'react-otp-input';
class LoginPage extends Component {

    constructor() {
        super();
        this.state = {
            login: {
                userName: "",
                password: ""
            },
            enableNext: false,
            showError: false,
            showPassword: false,
            useCode: false,
            OTP: '',
            seconds: 0,
        }
        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.login !== this.state.login) {
            if ((this.state.login.userName.length !== 0) && (this.state.login.password.length !== 0)) {
                this.setState({
                    enableNext: true
                })
            } else {
                this.setState({
                    enableNext: false
                })
            }
        }
        if (prevProps.postLoginDataState !== this.props.postLoginDataState) {
            if (this.props.postLoginDataState === "ERROR") {
                this.setState({
                    showError: true
                })
            }
            if (this.props.postLoginDataState === "SUCCESS") {
                const redirectURI = this.props.loginData['redirectURI'];
                window.location.href = redirectURI;
            }
        }

        if (prevProps.postRequestOTPState !== this.props.postRequestOTPState) {
            if (this.props.postRequestOTPState === "SUCCESS") {
                this.setState({
                    useCode: true,
                    seconds: this.props.waitingTime
                })
                this.startTimer()
            }
        }
    }

    handleInputChange = (event, inputIdentifier) => {
        let updatedFormData = {
            ...this.state.login,
        }
        updatedFormData[inputIdentifier] = event.target ? event.target.value : event.value;
        this.setState({
            login: updatedFormData,
        });
    }

    handleNext = (event) => {
        event.preventDefault();
        if (this.state.useCode) {
            let payload = {
                userName: this.state.login.userName,
                otp: this.state.OTP
            }
            this.props.onPostLoginData(payload);
        } else {
            let data = { ...this.state.login }
            this.props.onPostLoginData(data);
        }
    }

    handleForgotPasswordUrl = () => {
        const params = new URLSearchParams(window.location.search);
        if (params.has('defaultURI')) {
            return `/forgot-password?${params.toString()}`
        }
        return `/forgot-password`;
    }

    checkValidUserName = () => {
        let user = this.state.login.userName;
        if (/^([+][9][1]|[9][1]|[0]){0,1}([ ]{0,1})([0-9]{1})([0-9]{9})$/.test(user) ||
            // eslint-disable-next-line no-useless-escape
            /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(user)) {
            return true
        }
        return false;
    }

    handleUseCode = () => {
        this.handleGetOtp();
        this.setState({
            useCode: true,
            showError: false
        })
    }

    handleChange = (event) => {
        let updatedOTP = {
            ...this.state.OTP,
        }
        updatedOTP = event;
        this.setState({
            OTP: updatedOTP
        })
    }

    handleLoginDisable = () => {
        if (!isEmpty(this.state.login.userName)) {
            if (this.state.useCode) {
                if ((this.state.OTP.length === 4)) {
                    return false
                }
            } else {
                if (!isEmpty(this.state.login.password)) {
                    return false
                }
            }
        }
        return true;
    }

    startTimer() {
        if (this.timer === 0 && this.state.seconds >= 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }

    countDown = () => {
        let secondsCurrent = this.state.seconds - 1;
        this.setState({
            seconds: secondsCurrent,
        });
        if (secondsCurrent === 0) {
            clearInterval(this.timer);
        }
    }

    handleGetOtp = () => {
        let userType = ''
        if (/^([+][9][1]|[9][1]|[0]){0,1}([ ]{0,1})([0-9]{1})([0-9]{9})$/.test(this.state.login.userName)) {
            userType = 'SMS'
            // eslint-disable-next-line no-useless-escape
        } else if (/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(this.state.login.userName)) {
            userType = 'EMAIL'
        }
        let data = { "userName": this.state.login.userName, "channel": userType }
        this.props.onPostRequestOTP(data);
        this.timer = 0;
    }

    handleEditUserName = () => {
        this.setState({ useCode: false, OTP: '', showError: false })
        clearInterval(this.timer);
    }

    render() {
        return (
            <div className={styles.container}>
                <div className={cx(styles.scrollbar, styles.pageContainer)}>
                    <form>
                        <div className={styles.alignMiddle}>
                            <div className={styles.alignDots}><img src={dots} alt="betterplace" height="66px" /></div>
                            <div style={{ marginBottom: '2px', marginTop: '50px', marginLeft: '20px' }}>
                                <img src={BPlogo} alt="bplogo" height="40px" />
                            </div>
                            <div className={styles.loginHeadBold}> betterplace</div>
                            {
                                this.state.useCode ?
                                    <div>
                                        <div className={styles.loginHeadingOtp}>Enter OTP</div>
                                        <div className={styles.loginHeadingSmall}>Betterplace has sent you a OTP to</div>
                                        <div>
                                            <div className={styles.textPrimary}>{this.state.login.userName}
                                            <img src={editIcon} alt="edit" height="16px" onClick={() => this.handleEditUserName()}
                                                className={cx(styles.pointer, "ml-2")} />
                                            </div>
                                            
                                            <OtpInput
                                                value={this.state.OTP}
                                                onChange={(e) => this.handleChange(e)}
                                                numInputs={4}
                                                inputStyle={styles.otpContainer}
                                                isInputNum
                                                focusStyle={{outline: 'none'}}
                                            />
                                            <div className={styles.resendContainer} >
                                                <div className={this.state.seconds > 0 ? styles.resendTextDisabled : styles.resendText} onClick={() => this.handleGetOtp()}>
                                                    <img src={refreshIcon} alt="refesh" className="mr-1" height="16px" />
                                                    resend otp
                                                </div>
                                                <div className={cx("ml-auto", this.state.seconds > 0 ? styles.resendText : styles.resendTextDisabled)}>{this.state.seconds} second(s)</div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <div className={styles.loginHeading}>Login to your account</div>
                                        <div className={styles.inputs}>
                                            <div className={styles.label}>Email ID / Phone Number</div>
                                            <input
                                                name="userName"
                                                className={cx(styles.inputField, this.state.showError ? styles.error : null)}
                                                type="text"
                                                placeholder="enter email / mobile"
                                                onChange={(event) => this.handleInputChange(event, 'userName')}
                                                value={this.state.login.userName}
                                            />
                                        </div>
                                        <div className={cx(styles.inputs)} style={{marginTop:'20px'}}>
                                            <div className={styles.label}>Password</div>
                                            <PasswordInput
                                                placeholder="enter password"
                                                value={this.state.login.password}
                                                onChange={(event) => this.handleInputChange(event, 'password')}
                                                className={styles.mt8}
                                                error={this.state.showError}
                                            />

                                            <div className={cx("text-right", styles.mt8)}>
                                                <Link to={this.handleForgotPasswordUrl()} className={styles.NavLinkfont}>
                                                    Forgot Password?
                                                </Link>
                                            </div>
                                        </div>
                                        {
                                            this.checkValidUserName() &&
                                        <div className={styles.linkTextMedium} onClick={() => this.handleUseCode()}>
                                            Login with OTP
                                        </div>
                                        }

                                    </div>
                            }
                            {this.state.showError &&
                                <div className={styles.warning}>
                                    <img src={warning} alt='warning' className="mr-1" />
                                    {this.props.error}
                                </div>
                            }
                            <button
                                tabIndex="0"
                                onClick={(event) => this.handleNext(event)}
                                disabled={this.handleLoginDisable()}
                                className={cx(!this.handleLoginDisable() ? styles.buttonNextEnable : styles.buttonNextDisable,
                                    styles.buttonNext, styles.marginTop)}>
                                <span className={styles.nextText}>Login</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        postLoginDataState: state.login.postLoginDataState,
        postRequestOTPState: state.login.postRequestOTPState,
        error: state.login.error,
        loginData: state.login.loginData,
        waitingTime: state.login.waitingTime
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onPostLoginData: (data) => dispatch(actions.postLoginData(data)),
        onPostRequestOTP: (data) => dispatch(actions.requestOtp(data)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginPage));