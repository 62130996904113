import React, { Component } from "react";
import cx from "classnames";
import Input from "../../atoms/Input/Input";
import _ from 'lodash';
import styles from './MultipleInput.module.scss';
import close from '../../../assets/icons/closeNotification.svg';
import tick from '../../../assets/icons/addMore.svg';

class MultipleInput extends Component {
    state={
        URI:"",
        URIArr:[]
    }

    componentDidMount(){
        let updatedUriArr = this.props.UriArr
        this.setState({
            URIArr : updatedUriArr
        })
    }

    componentDidUpdate(prevProps){
        if(!_.isEqual(this.props.UriArr,prevProps.UriArr)){
            this.setState({
                URIArr :this.props.UriArr
            })
        }
    }

    handleInputChange = (event, inputIdentifier) => {
        let updatedData = {
            ...this.state.URI,
        }
        updatedData = event.target ? event.target.value : event.value;
        this.setState({
            URI: updatedData,
           
        });
    };

    handleAddUri=(event)=>{
        event.preventDefault();
        let uri = this.state.URI;
        let uriArr = [...this.state.URIArr];
        uriArr.push(uri);
        this.setState({
            URI : "",
            URIArr : uriArr
        })
        this.props.changed(uriArr);
    }

    deleteArrItem=(uri)=>{
        let updatedUriArr = _.cloneDeep(this.state.URIArr);
        let remove = updatedUriArr.filter(function(value, index){
            return value !== uri
        })
        this.setState({
            URIArr:remove
        })
        this.props.changed(remove);
    }

    render(){
        return(
            <React.Fragment>
            
            <div className='row'>
            <Input
                name="redirectURIs"
                className="col-4 pr-3 pl-3 ml-3 "
                label = "redirect URI"
                type ="text"
                placeholder="redirect URI"
                changed={(event) => this.handleInputChange(event, 'redirectURIs')}
                value={this.state.URI}
            />
            {this.state.URI.length !== 0 ? 
            <span onClick={(event)=>this.handleAddUri(event)} className={styles.rightTick}>
                <img src={tick} alt="tick"/>
            </span>
            : null }
            
            </div>
            {this.state.URIArr.map((uri,index)=>{
                return(
                    <div className={cx(styles.option,"ml-3")} key={index}>
                    <div key={index}>{uri}
                    <span className={styles.alignClose} onClick={()=>this.deleteArrItem(uri)}><img src={close} alt="img"/></span></div>
                    </div>
                )
            })}
            
        </React.Fragment>
        )
    }
}

export default MultipleInput;
