import * as actionTypes from './actionTypes';

const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

const initialState = {
    getActivityListState: 'INIT',
    postRevokeState: 'INIT',
    activity:[]
}

//INIT STATE REDUCER
const initState = () => {
    return initialState;
}

//GET USERS
const getActivityListLoading = (state) => {
    return updateObject(state, {
        getActivityListState: 'LOADING'
    });
}

const getActivityListSuccess = (state, action) => {
    return updateObject(state, {
        getActivityListState:'SUCCESS',
        activity: action.activity,
    });
};

const getActivityListError = (state, action) => {
    return updateObject(state, {
        getActivityListState: 'ERROR',
        error: action.error,
    });
};

//POST REVOKE
const postRevokeLoading = (state) => {
    return updateObject(state, {
        postRevokeState: 'LOADING'
    });
}

const postRevokeSuccess = (state, action) => {
    return updateObject(state, {
        postRevokeState:'SUCCESS',
        // users: action.users,
    });
};

const postRevokeError = (state, action) => {
    return updateObject(state, {
        postRevokeState: 'ERROR',
        error: action.error,
        
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        
        case actionTypes.INIT_STATE: return initState();

        case actionTypes.GET_ACTIVITY_LIST_LOADING: return getActivityListLoading(state);
        case actionTypes.GET_ACTIVITY_LIST_SUCCESS: return getActivityListSuccess(state, action);
        case actionTypes.GET_ACTIVITY_LIST_ERROR: return getActivityListError(state, action);

        case actionTypes.POST_REVOKE_LOADING: return postRevokeLoading(state);
        case actionTypes.POST_REVOKE_SUCCESS: return postRevokeSuccess(state, action);
        case actionTypes.POST_REVOKE_ERROR: return postRevokeError(state, action);
        
        default: return state;
    }
};

export default reducer;