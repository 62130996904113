export const INIT_STATE = 'INIT_USERS_LIST_STATE' ;

export const GET_USERS_LIST_LOADING = 'GET_USERS_LIST_LOADING';
export const GET_USERS_LIST_SUCCESS = 'GET_USERS_LIST_SUCCESS';
export const GET_USERS_LIST_ERROR = 'GET_USERS_LIST_ERROR';

export const POST_USER_LOADING = 'POST_USER_LOADING';
export const POST_USER_SUCCESS = 'POST_USER_SUCCESS';
export const POST_USER_ERROR = 'POST_USER_ERROR';

export const GET_PERMISSION_LIST_LOADING = "GET_PERMISSION_LIST_LOADING";
export const GET_PERMISSION_LIST_SUCCESS = "GET_PERMISSION_LIST_SUCCESS";
export const GET_PERMISSION_LIST_ERROR = "GET_PERMISSION_LIST_ERROR";

export const GET_POLICY_LOADING = "GET_POLICY_LOADING";
export const GET_POLICY_SUCCESS = "GET_POLICY_SUCCESS";
export const GET_POLICY_ERROR = "GET_POLICY_ERROR";

export const PUT_POLICY_DATA_LOADING = "PUT_POLICY_DATA_LOADING";
export const PUT_POLICY_DATA_SUCCESS = "PUT_POLICY_DATA_SUCCESS";
export const PUT_POLICY_DATA_ERROR = "PUT_POLICY_DATA_ERROR";

export const GET_TOKEN_LOADING = "GET_TOKEN_LOADING";
export const GET_TOKEN_SUCCESS = "GET_TOKEN_SUCCESS";
export const GET_TOKEN_ERROR = "GET_POLICY_ERROR";

export const POST_REVOKE_LOADING = 'POST_REVOKE_LOADING';
export const POST_REVOKE_SUCCESS = 'POST_REVOKE_SUCCESS';
export const POST_REVOKE_ERROR = 'POST_REVOKE_ERROR';

export const POST_TOKEN_LOADING = 'POST_TOKEN_LOADING';
export const POST_TOKEN_SUCCESS = 'POST_TOKEN_SUCCESS';
export const POST_TOKEN_ERROR = 'POST_TOKEN_ERROR';