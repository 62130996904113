import * as actionTypes from './actionTypes';
import axios from 'axios';

const IDENTITY = process.env.REACT_APP_IDENTITY_API_URL;

//post login data
export const postLoginData = (data) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.POST_LOGIN_DATA_LOADING
        })
        let apiUrl = IDENTITY + '/login';
        axios.post(apiUrl,data)
        
            .then(response => {
                if (response.status === 200 || response.status === 201) {
                    dispatch({
                        type: actionTypes.POST_LOGIN_DATA_SUCCESS,
                        loginData: response.data
                    });
                }
            })
            .catch(error => {
                let errMsg  = error;
                // Temporary fix to gracefully handle redirection to blank page in case of error 403
                // Should be removed after error message is fixed from back end
                if(error.response && typeof error.response.data === 'string') {
                    error.response.data = {};
                    error.response.data.errorMessage = 'Something went wrong, please try again.';
                }
                if(error.response && error.response.data.errorMessage){
                    errMsg = error.response.data.errorMessage
                }
                dispatch({
                    type: actionTypes.POST_LOGIN_DATA_ERROR,
                    error: errMsg,
                });
            });
    };
}

//post login data
export const requestOtp = (data) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.POST_REQUEST_OTP_LOADING
        })
        let apiUrl = IDENTITY + '/login/send-otp';
        axios.post(apiUrl,data)
        
            .then(response => {
                if (response.status === 200 || response.status === 201) {
                    dispatch({
                        type: actionTypes.POST_REQUEST_OTP_SUCCESS,
                        waitingTime: response.data
                    });
                }
            })
            .catch(error => {
                let errMsg  = error;
                if(error.response && error.response.data.errorMessage){
                    errMsg = error.response.data.errorMessage
                }
                dispatch({
                    type: actionTypes.POST_REQUEST_OTP_ERROR,
                    error: errMsg,
                });
            });
    };
}