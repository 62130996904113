import React, { Component } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";

import * as actions from "./Store/action";
import styles from "./Resources.module.scss";
import cx from "classnames";
import LeftNavBar from '../../components/atoms/LeftNavBar/LeftNavBar';
import Button from '../../components/atoms/Button/Button';

class Resources extends Component {

    componentDidMount() {
        this.props.getResources();
    }

    render() {
        return (
            <React.Fragment >   
                <div className="row">
                    <div className={cx("col-1 px-0")}>
                        <LeftNavBar />
                    </div>
                    <div className={cx("col-9 pt-4", styles.main)}>
                        <NavLink to={"/resource/add"} >
                            <Button label="add new resource" style={{ float: "right" }} /><br />
                        </NavLink>
                        <div className={cx("row mt-5", styles.scroll,styles.scrollbar)}>{this.props.resources.map((resource, index) => {
                            let url = "/resource/" + resource._id;
                            return (
                                <div className={cx("col-6 px-2 pl-3", styles.clientCardAlign)} key={index}>
                                    <Link to={url} style={{ textDecoration: "none" }}>
                                        <div className={styles.ResourceCard}>
                                            <div className={styles.cardTextMedium}>resource : {resource.resource}</div>
                                            <div className={styles.cardTextMedium}>method : {resource.method}</div>
                                            <div className={styles.cardTextSmall}>service : {resource.serviceLabel}</div>
                                            <div className={styles.cardTextSmall}>module : {resource.moduleLabel}</div>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
const mapStateToProps = state => {
    return {
        getResourceListState: state.resource.getResourceListState,
        resources: state.resource.resources
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getResources: () => dispatch(actions.getResources()),
       
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Resources));