import * as actionTypes from './actionTypes';
import axios from 'axios';
import _ from 'lodash' ;

const IDENTITY = process.env.REACT_APP_IDENTITY_API_URL;

export const initState = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.INIT_STATE
        })
    };
};

//get all resources
export const getResources = () => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.GET_RESOURCES_LIST_LOADING
        })
        let apiUrl = IDENTITY+'/resource';
        axios.get(apiUrl)
            .then(response => {
                if (response.status === 200 || response.status === 201) {
                    dispatch({
                        type: actionTypes.GET_RESOURCES_LIST_SUCCESS,
                        resources: response.data
                    });
                }
            })
            .catch(error => {
                let errMsg  = error;
                if(error.response && error.response.data.errorMessage){
                    errMsg = error.response.data.errorMessage;
                }
                dispatch({
                    type: actionTypes.GET_RESOURCES_LIST_ERROR,
                    error: errMsg
                });
            });
    };
}

//get resource by Id
export const getResourceData = (Id) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.GET_RESOURCES_DATA_LOADING
        })
        let apiUrl = IDENTITY+'/resource/'+Id;
        axios.get(apiUrl)
            .then(response => {
                if (response.status === 200 || response.status === 201) {
                    dispatch({
                        type: actionTypes.GET_RESOURCES_DATA_SUCCESS,
                        resourceData: response.data
                    });
                }
            })
            .catch(error => {
                let errMsg  = error;
                if(error.response && error.response.data.errorMessage){
                    errMsg = error.response.data.errorMessage;
                }
                dispatch({
                    type: actionTypes.GET_RESOURCES_DATA_ERROR,
                    error: errMsg
                });
            });
    };
}

//Post resource
export const postResource = (data) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.POST_RESOURCES_LIST_LOADING
        })
        let apiUrl =  IDENTITY+'/resource';
        axios.post(apiUrl,data)
            .then(response => {
                if (response.status === 200 || response.status === 201) {
                    dispatch({
                        type: actionTypes.POST_RESOURCES_LIST_SUCCESS,
                        resourceId: response.data
                    });
                }
            })
            .catch(error => {
                let errMsg  = error;
                if(error.response && error.response.data.errorMessage){
                    errMsg = error.response.data.errorMessage;
                }
                dispatch({
                    type: actionTypes.POST_RESOURCES_LIST_ERROR,
                    error: errMsg
                });
            });
    };
}

// Put Resource

export const putResource = (data) => {
    return (dispatch,getState) => {
        dispatch({
            type: actionTypes.PUT_RESOURCES_LIST_LOADING
        })
        let apiUrl = IDENTITY +'/resource/' + data._id;
        axios.put(apiUrl,data)
            .then(response => {
                if (response.status === 200 || response.status === 201) {
                    let updatedResources = getState().resource.resources ;
                    updatedResources = updatedResources.map((Item) => {
                        if(_.isEqual(Item._id , data._id)){
                            return(data);
                        }
                        return(Item);
                    });
                    dispatch({
                        type: actionTypes.PUT_RESOURCES_LIST_SUCCESS,
                        resources: updatedResources
                    });
                }
            })
            .catch(error => {
                let errMsg  = error;
                if(error.response && error.response.data.errorMessage){
                    errMsg = error.response.data.errorMessage;
                }
                dispatch({
                    type: actionTypes.PUT_RESOURCES_LIST_ERROR,
                    error: errMsg
                });
            });
    };
}

// Delete Resource

export const deleteResource = (id ) => {
    return (dispatch,getState) => {
        dispatch({
            type: actionTypes.DELETE_RESOURCES_LIST_LOADING
        })
        let apiUrl =  IDENTITY+'/resource/' + id;
        axios.delete(apiUrl)
            .then(response => {
                if (response.status === 200 || response.status === 201) {
                    let updatedResources = getState().resource.resources ;
                    
                    updatedResources = updatedResources.filter((Item) => {
                        if(Item._id === id) return null;
                        else return Item;
                    });
                    dispatch({
                        type: actionTypes.DELETE_RESOURCES_LIST_SUCCESS,
                        resources : updatedResources
                    });
                }
            })
            .catch(error => {
                let errMsg  = error;
                if(error.response && error.response.data.errorMessage){
                    errMsg = error.response.data.errorMessage;
                }
                dispatch({
                    type: actionTypes.DELETE_RESOURCES_LIST_ERROR,
                    error: errMsg
                });
            });
    };
}


