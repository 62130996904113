import { combineReducers } from 'redux';
import clientListReducer from '../ClientList/Store/reducer';
import clientDetailsReducer from "../ClientDetails/Store/reducer";

const clientReducer = combineReducers({
    
    clientList : clientListReducer,
    clientDetails : clientDetailsReducer
    
});

export default clientReducer;