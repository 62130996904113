import React from 'react';
import styles from './Button.module.scss';
import cx from 'classnames';

const button = (props) => {

    let button = null;
    switch (props.type) {
        case 'cancel':
            button = <span>
                <button className={props.isDisabled ?
                    cx(styles.cancelButtonDisabled,props.className) :
                    cx(styles.cancelButton,props.className)}
                    onClick={props.clickHandler}
                    disabled={props.isDisabled}
                >
                    <span className={styles.cancelLabel}>{props.label}</span>
                </button>
            </span>
            break;
        
            case 'delete':
                button=
                <span>
                 <button className={props.isDisabled ?
                    cx(styles.cancelButtonDisabled,props.className) :
                    cx(styles.delete,props.className)}
                    onClick={props.clickHandler}
                    disabled={props.isDisabled}
                >
                    <span className={styles.cancelLabel}>{props.label}</span>
                </button>
                </span>
                break;

            default:
            button = <span style={props.style}>
                <button className={props.isDisabled ?
                    cx(styles.DisabledButton,props.className) :
                    cx(styles.saveButton,props.className)}
                    onClick={props.clickHandler}
                    disabled={props.isDisabled}
                >
                    <span className={styles.saveLabel}>{props.label}</span>
                </button>
            </span>
            break;
    }

    return (
        <React.Fragment>
            {button}
        </React.Fragment>
    )
};

export default button;