import * as actionTypes from './actionTypes';
import { updateObject } from './utility';

const initialState = {
    getUsersListState: 'INIT',
    postUserState: 'INIT',
    userList:[],
    getPermissionState: 'INIT',
    permissionData: [],
    getPolicyState: 'INIT',
    putPolicyDataState: 'INIT',
    policyData: null,
    TokenData: null,
    getTokenState: 'INIT',
    postRevokeState: 'INIT',
    postTokenState: 'INIT',
}

//INIT STATE REDUCER
const initState = () => {
    return initialState;
}

//GET USERS
const getUsersListLoading = (state) => {
    return updateObject(state, {
        getUsersListState: 'LOADING'
    });
}

const getUsersListSuccess = (state, action) => {
    return updateObject(state, {
        getUsersListState:'SUCCESS',
        userList: action.users,
    });
};

const getUsersListError = (state, action) => {
    return updateObject(state, {
        getUsersListState: 'ERROR',
        // users: [],
        error: action.error,
    });
};


//POST USERS
const postUserLoading = (state) => {
    return updateObject(state, {
        postUserState: 'LOADING'
    });
}

const postUserSuccess = (state, action) => {
    return updateObject(state, {
        postUserState:'SUCCESS'
    });
};

const postUserError = (state, action) => {
    return updateObject(state, {
        postUserState: 'ERROR',
        // users: [],
        error: action.error,
    });
};

//Get Permissions Reducers
const getPermissionLoading = ( state ) => {
    return updateObject( state, {
        getPermissionState: 'LOADING',
    });
};

const getPermissionSuccess = ( state, action ) => {
    return updateObject( state, {
        getPermissionState: 'SUCCESS',
        permissionData: action.permissionData,
        error: null
    });
};

const getPermissionError = ( state, action ) => {
    return updateObject( state, {
        getPermissionState: 'ERROR',
        error: action.error
    });
};


//Get Policy Reducer
const getPolicyLoading = ( state, action) => {
    return updateObject( state, {
        getPolicyState: 'LOADING'
    })
}

const getPolicySuccess = ( state, action) => {
    return updateObject( state, {
        getPolicyState: 'SUCCESS',
        policyData: action.policyData
    })
}

const getPolicyError = ( state, action) => {
    return updateObject( state, {
        getPolicyState: 'ERROR'
    })
}

//Put Policy Data
const putPolicyDataLoading = ( state ) => {
    return updateObject( state, {
        putPolicyDataState: 'LOADING'
    })
}

const putPolicyDataSuccess = ( state , action) => {
    return updateObject( state, {
        putPolicyDataState: 'SUCCESS',
        policyData: action.policyData
    })
}

const putPolicyDataError = ( state ) => {
    return updateObject( state, {
        putPolicyDataState: 'ERROR'
    })
}

//Get Token Reducer
const getTokenLoading = ( state, action) => {
    return updateObject( state, {
        getTokenState: 'LOADING'
    })
}

const getTokenSuccess = ( state, action) => {
    return updateObject( state, {
        getTokenState: 'SUCCESS',
        TokenData: action.TokenData
    })
}

const getTokenError = ( state, action) => {
    return updateObject( state, {
        getTokenState: 'ERROR'
    })
}

//POST REVOKE
const postRevokeLoading = (state) => {
    return updateObject(state, {
        postRevokeState: 'LOADING'
    });
}

const postRevokeSuccess = (state, action) => {
    return updateObject(state, {
        postRevokeState:'SUCCESS',
        // users: action.users,
    });
};

const postRevokeError = (state, action) => {
    return updateObject(state, {
        postRevokeState: 'ERROR',
        error: action.error,
        
    });
};

//POST Token
const postTokenLoading = (state) => {
    return updateObject(state, {
        postTokenState: 'LOADING'
    });
}

const postTokenSuccess = (state, action) => {
    return updateObject(state, {
        postTokenState:'SUCCESS',
        // users: action.users,
    });
};

const postTokenError = (state, action) => {
    return updateObject(state, {
        postTokenState: 'ERROR',
        error: action.error,
        
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        
        case actionTypes.INIT_STATE: return initState();

        case actionTypes.GET_USERS_LIST_LOADING: return getUsersListLoading(state);
        case actionTypes.GET_USERS_LIST_SUCCESS: return getUsersListSuccess(state, action);
        case actionTypes.GET_USERS_LIST_ERROR: return getUsersListError(state, action);

        case actionTypes.POST_USER_LOADING: return postUserLoading(state);
        case actionTypes.POST_USER_SUCCESS: return postUserSuccess(state, action);
        case actionTypes.POST_USER_ERROR: return postUserError(state, action);

        case actionTypes.GET_PERMISSION_LIST_LOADING: return getPermissionLoading(state, action);
        case actionTypes.GET_PERMISSION_LIST_SUCCESS: return getPermissionSuccess(state, action);
        case actionTypes.GET_PERMISSION_LIST_ERROR: return getPermissionError(state, action);

        case actionTypes.GET_POLICY_LOADING: return getPolicyLoading(state, action);
        case actionTypes.GET_POLICY_SUCCESS: return getPolicySuccess(state, action);
        case actionTypes.GET_POLICY_ERROR: return getPolicyError(state, action);

        case actionTypes.PUT_POLICY_DATA_LOADING: return putPolicyDataLoading(state, action);
        case actionTypes.PUT_POLICY_DATA_SUCCESS: return putPolicyDataSuccess(state, action);
        case actionTypes.PUT_POLICY_DATA_ERROR: return putPolicyDataError(state, action);

        case actionTypes.GET_TOKEN_LOADING: return getTokenLoading(state, action);
        case actionTypes.GET_TOKEN_SUCCESS: return getTokenSuccess(state, action);
        case actionTypes.GET_TOKEN_ERROR: return getTokenError(state, action);

        case actionTypes.POST_REVOKE_LOADING: return postRevokeLoading(state);
        case actionTypes.POST_REVOKE_SUCCESS: return postRevokeSuccess(state, action);
        case actionTypes.POST_REVOKE_ERROR: return postRevokeError(state, action);

        case actionTypes.POST_TOKEN_LOADING: return postTokenLoading(state);
        case actionTypes.POST_TOKEN_SUCCESS: return postTokenSuccess(state, action);
        case actionTypes.POST_TOKEN_ERROR: return postTokenError(state, action);

        default: return state;
    }
};

export default reducer;