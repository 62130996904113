import * as actionTypes from './actionTypes';
import { updateObject } from '../../ClientMgmtStore/utility';

const initialState = {
    postClientDataState: 'INIT',
    putClientDataState: 'INIT',
    getClientDetailState : 'INIT',
    deleteClientState: 'INIT',
    clientDetail:{},
    clientId: null,
    clientData : {}
}

//INIT STATE REDUCER
const initState = () => {
    return initialState;
}

//post client data
const postClientDataLoading = (state) => {
    return updateObject(state, {
        postClientDataState: 'LOADING'
    });
}

const postClientDataSuccess = (state, action) => {
    return updateObject(state, {
        postClientDataState:'SUCCESS',
        clientId: action.clientId,
        error: null
    });
};

const postClientDataError = (state, action) => {
    return updateObject(state, {
        postClientDataState: 'ERROR',
        error: action.error,
    });
};

//put client data
const putClientDataLoading = (state) => {
    return updateObject(state, {
        putClientDataState: 'LOADING'
    });
}

const putClientDataSuccess = (state, action) => {
    return updateObject(state, {
        putClientDataState:'SUCCESS',
        clientData: action.clientData,
        error: null
    });
};

const putClientDataError = (state, action) => {
    return updateObject(state, {
        putClientDataState: 'ERROR',
        error: action.error,
    });
};

//DELETE client 
const deleteClientLoading = (state) => {
    return updateObject(state, {
        deleteClientState: 'LOADING'
    });
}

const deleteClientSuccess = (state, action) => {
    return updateObject(state, {
        deleteClientState:'SUCCESS',
        error: null
    });
};

const deleteClientError = (state, action) => {
    return updateObject(state, {
        deleteClientState: 'ERROR',
        error: action.error,
    });
};

//get client detai
const getClientDetailLoading = (state) => {
    return updateObject(state, {
        getClientDetailState: 'LOADING'
    });
}

const getClientDetailSuccess = (state, action) => {
    
    return updateObject(state, {
        
        getClientDetailState:'SUCCESS',
        clientDetail: action.clientDetail,
        error: null
    });
};

const getClientDetailError = (state, action) => {
    return updateObject(state, {
        getClientDetailState: 'ERROR',
        error: action.error,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INIT_STATE: return initState();

        case actionTypes.POST_CLIENT_DATA_LOADING: return postClientDataLoading(state);
        case actionTypes.POST_CLIENT_DATA_SUCCESS: return postClientDataSuccess(state, action);
        case actionTypes.POST_CLIENT_DATA_ERROR: return postClientDataError(state, action);

        case actionTypes.PUT_CLIENT_DATA_LOADING: return putClientDataLoading(state);
        case actionTypes.PUT_CLIENT_DATA_SUCCESS: return putClientDataSuccess(state, action);
        case actionTypes.PUT_CLIENT_DATA_ERROR: return putClientDataError(state, action);

        case actionTypes.DELETE_CLIENT_LOADING: return deleteClientLoading(state);
        case actionTypes.DELETE_CLIENT_SUCCESS: return deleteClientSuccess(state, action);
        case actionTypes.DELETE_CLIENT_ERROR: return deleteClientError(state, action);

        case actionTypes.GET_CLIENT_DETAIL_LOADING: return getClientDetailLoading(state);
        case actionTypes.GET_CLIENT_DETAIL_SUCCESS: return getClientDetailSuccess(state, action);
        case actionTypes.GET_CLIENT_DETAIL_ERROR: return getClientDetailError(state, action);

        default: return state;
    }
};

export default reducer;

