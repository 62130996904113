import * as actionTypes from './actionTypes';
import { updateObject } from './utility';

const initialState = {
    getResourceListState: 'INIT',
    postResourceState:'INIT',
    putResourceState: 'INIT',
    deleteResourceState: 'INIT',
    resources: [],
    resourceData : {},
    getResourceDataState: 'INIT',
    resourceId:''
}

//INIT STATE REDUCER
const initState = () => {
    return initialState;
}

//GET RESOURCE LIST
const getResourceListLoading = (state) => {
    return updateObject(state, {
        getResourceListState: 'LOADING'
    });
}

const getResourceListSuccess = (state, action) => {
    return updateObject(state, {
        getResourceListState:'SUCCESS',
        resources: action.resources,
    });
};

const getResourceListError = (state, action) => {
    return updateObject(state, {
        getResourceListState: 'ERROR',
        error: action.error,
    });
};

//GET RESOURCE DATA
const getResourceDataLoading = (state) => {
    return updateObject(state, {
        getResourceDataState: 'LOADING'
    });
}

const getResourceDataSuccess = (state, action) => {
    return updateObject(state, {
        getResourceDataState:'SUCCESS',
        resourceData: action.resourceData,
    });
};

const getResourceDataError = (state, action) => {
    return updateObject(state, {
        getResourceDataState: 'ERROR',
        error: action.error,
    });
};

//POST RESOURCE LIST

const postResourceListLoading = (state) => {        
    return updateObject(state, {
        postResourceState: 'LOADING'
    });
}

const postResourceListSuccess = (state, action) => {        
    return updateObject(state, {
        postResourceState:'SUCCESS',
        resourceId: action.resourceId
    });
};

const postResourceListError = (state, action) => {        
    return updateObject(state, {
        postResourceState: 'ERROR',
        error: action.error,
    });
};


// PUT RESOURCE LIST 
const putResourceListLoading = (state) => {   
    return updateObject(state, {
        putResourceState: 'LOADING'
    });
}

const putResourceListSuccess = (state, action) => {       
    return updateObject(state, {
        putResourceState:'SUCCESS',
        resources: action.resources,
    });
};

const putResourceListError = (state, action) => {   
    return updateObject(state, {
        putResourceState: 'ERROR',
        error: action.error,
    });
};

// DELETE RESOURCE LIST 
const deleteResourceListLoading = (state) => {
    return updateObject(state, {
        deleteResourceState: 'LOADING'
    });
}

const deleteResourceListSuccess = (state, action) => {      
    return updateObject(state, {
        deleteResourceState:'SUCCESS',
        resources: action.resources,
    });
};

const deleteResourceListError = (state, action) => {
    return updateObject(state, {
        deleteResourceState: 'ERROR',
        error: action.error,
    });
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        
        case actionTypes.INIT_STATE: return initState();

        case actionTypes.GET_RESOURCES_LIST_LOADING: return getResourceListLoading(state);
        case actionTypes.GET_RESOURCES_LIST_SUCCESS: return getResourceListSuccess(state, action);
        case actionTypes.GET_RESOURCES_LIST_ERROR: return getResourceListError(state, action);

        case actionTypes.GET_RESOURCES_DATA_LOADING: return getResourceDataLoading(state);
        case actionTypes.GET_RESOURCES_DATA_SUCCESS: return getResourceDataSuccess(state, action);
        case actionTypes.GET_RESOURCES_DATA_ERROR: return getResourceDataError(state, action);

        case actionTypes.POST_RESOURCES_LIST_LOADING: return postResourceListLoading(state);
        case actionTypes.POST_RESOURCES_LIST_SUCCESS: return postResourceListSuccess(state, action);
        case actionTypes.POST_RESOURCES_LIST_ERROR: return postResourceListError(state, action);

        case actionTypes.PUT_RESOURCES_LIST_LOADING: return putResourceListLoading(state);
        case actionTypes.PUT_RESOURCES_LIST_SUCCESS: return putResourceListSuccess(state, action);
        case actionTypes.PUT_RESOURCES_LIST_ERROR: return putResourceListError(state, action);

        case actionTypes.DELETE_RESOURCES_LIST_LOADING: return deleteResourceListLoading(state);
        case actionTypes.DELETE_RESOURCES_LIST_SUCCESS: return deleteResourceListSuccess(state, action);
        case actionTypes.DELETE_RESOURCES_LIST_ERROR: return deleteResourceListError(state, action);

        default: return state;
    }
};

export default reducer;

