export const INIT_STATE = 'INIT_CLIENT_DATA_STATE';

export const POST_CLIENT_DATA_LOADING = 'POST_CLIENT_DATA_LOADING';
export const POST_CLIENT_DATA_SUCCESS = 'POST_CLIENT_DATA_SUCCESS';
export const POST_CLIENT_DATA_ERROR = 'POST_CLIENT_DATA_ERROR';

export const PUT_CLIENT_DATA_LOADING = 'PUT_CLIENT_DATA_LOADING';
export const PUT_CLIENT_DATA_SUCCESS = 'PUT_CLIENT_DATA_SUCCESS';
export const PUT_CLIENT_DATA_ERROR = 'PUT_CLIENT_DATA_ERROR';

export const DELETE_CLIENT_LOADING = 'DELETE_CLIENT_LOADING';
export const DELETE_CLIENT_SUCCESS = 'DELETE_CLIENT_SUCCESS';
export const DELETE_CLIENT_ERROR = 'DELETE_CLIENT_ERROR';

export const GET_CLIENT_DETAIL_LOADING = 'GET_CLIENT_DETAIL_LOADING';
export const GET_CLIENT_DETAIL_SUCCESS = 'GET_CLIENT_DETAIL_SUCCESS';
export const GET_CLIENT_DETAIL_ERROR = 'GET_CLIENT_DETAIL_ERROR';