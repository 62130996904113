import React from 'react';

import './index.module.scss';
import App from './App';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Route } from "react-router-dom";
import { Provider } from 'react-redux';
import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import axios from 'axios';
import Cookies from 'universal-cookie';


import * as serviceWorker from './serviceWorker';
import * as AuthConst from './services/Auth/helpers/AuthConstants';

import authReducer from './services/Auth/Store/reducer';
import clientMgmtReducer from "./containers/ClientMgmt/ClientMgmtStore/reducer";
import loginReducer from "./containers/LoginPage/Store/reducer";
import resetReducer from "./containers/ResetPassword/Store/reducer";
import forgotReducer from "./containers/ForgotPassword/Store/reducer";
import resourceReducer from './containers/Resources/Store/reducer';
import userReducer from './containers/Users/Store/reducer';
import activityReducer from "./containers/Activity/Store/reducer";

import Auth from './services/Auth/Auth';
import OauthRedirect from './services/Auth/OauthRedirect';
import Login from './containers/LoginPage/LoginPage';
import ResetPassword from './containers/ResetPassword/ResetPassword';
import ForgotPassword from './containers/ForgotPassword/ForgotPassword';
import ErrorPage from "./components/molecules/ErrorHandler/ErrorPage/ErrorPage";
import SessionExpired from "./components/molecules/SessionExpired/SessionExpired";


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  auth: authReducer,
  login: loginReducer,
  reset: resetReducer,
  forgot: forgotReducer,
  clientMgmt: clientMgmtReducer,
  resource : resourceReducer,
  user: userReducer,
  activity : activityReducer

});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

let app = (
  <Provider store={store}>
    <BrowserRouter>
      <Auth>
        <App />
      </Auth>
      <Route path='/login' exact component={Login} />
      <Route path='/reset-password' exact component={ResetPassword} />
      <Route path='/forgot-password' exact component={ForgotPassword} />
      <Route path='/oauth-redirect' component={OauthRedirect} />
    </BrowserRouter>
  </Provider>
)

axios.interceptors.request.use(function (config) {
  const api = config.url.split("/");
  const cookies = new Cookies();
  const access_token = cookies.get('access_token');
  if(access_token !== undefined && config.headers.Authorization === undefined && api[api.length-1] !== "resetpassword" 
    && api[api.length-1] !== "forgotpassword" && api[api.length-1] !== "login"){
    config.headers.Authorization = 'Bearer ' + access_token;
  }
  return config;
});

let isAlreadyFetchingAccessToken = false
let subscribers = []

function onAccessTokenFetched(access_token) {
  subscribers = subscribers.filter(callback => callback(access_token))
}

function addSubscriber(callback) {
  subscribers.push(callback)
}

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  const { config, response: { status } } = error
  const originalRequest = config

  const api = config.url.split("/");
  if (status > 499 && api[api.length-1] !== "login" && api[api.length-1] !== "resetpassword" 
  && api[api.length-1] !== "forgotpassword" ) {
    ReactDOM.render(
      <Provider store={store}>
        <BrowserRouter>
          <ErrorPage errorCode={error.response.status} />
        </BrowserRouter>
      </Provider>,
      document.getElementById('root')
    );
  }
  else if (status > 499 && (api[api.length-1] === "login" || api[api.length-1] === "resetpassword" 
  || api[api.length-1] !== "forgotpassword") ){
    const params = new URLSearchParams(window.location.search);
    if (params.has('defaultURI')) {
      window.location = params.get('defaultURI');
    } else {
      window.location = process.env.REACT_APP_IDENTITY_URL;
    }
  }
  else if (status === 440) {
    const params = new URLSearchParams(window.location.search);
    if (params.has('defaultURI')) {
      window.location = params.get('defaultURI');
    } else {
      window.location = process.env.REACT_APP_IDENTITY_URL;
    }
  }
  else if ((status === 401) && (api[api.length-1] !== "token" && api[api.length-1] !== "resetpassword" 
    && api[api.length-1] !== "forgotpassword" && api[api.length-1] !== "login")) {
    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true
      const cookies = new Cookies();
      const refreshToken = cookies.get('refresh_token');
      if (refreshToken && refreshToken !== undefined) {
        const requestBody = {
          grant_type: "refresh_token",
          refresh_token: refreshToken
        };
        axios.post(`${AuthConst.identity}/token`, requestBody, {headers:AuthConst.headers})
          .then(response => {
              if (response.status === 200 || response.status === 201) {
                const newAccessToken = response.data.access_token;
                const accessTokenExpiry = response.data.access_token_expiry;
                const newRefreshToken = response.data.refresh_token;
                const refreshTokenExpiry = response.data.refresh_token_expiry;
                cookies.set('access_token', newAccessToken, { path: '/', expires: new Date(accessTokenExpiry) });
                cookies.set("refresh_token", newRefreshToken, { path: "/", expires: new Date(refreshTokenExpiry) });
                originalRequest.headers['Authorization'] = 'Bearer ' + newAccessToken;
                isAlreadyFetchingAccessToken = false;
                onAccessTokenFetched(newAccessToken)
              }
          })
          .catch(error => {
            ReactDOM.render(
              <Provider store={store}>
                <BrowserRouter>
                  <Auth>
                    <SessionExpired/>
                  </Auth>
                </BrowserRouter>
              </Provider>,
              document.getElementById('root')
            );
          })
      }else{
        ReactDOM.render(
          <Provider store={store}>
            <BrowserRouter>
              <Auth>
                <SessionExpired/>
              </Auth>
            </BrowserRouter>
          </Provider>,
          document.getElementById('root')
        );
      }
    }
    const retryOriginalRequest = new Promise((resolve) => {
      addSubscriber(access_token => {
        originalRequest.headers.Authorization = 'Bearer ' + access_token
        resolve(axios(originalRequest))
      })
    })
    return retryOriginalRequest
  }
  return Promise.reject(error)
})

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
