import React, { Component } from "react";
import styles from './ErrorNotification.module.scss';
import error from "../../../assets/icons/warning.svg";

class ErrorNotification extends Component {
  state={
    display: true
  }
  
  componentDidMount() {
    setTimeout(() => this.setState({
      display: false
    }), 2000)
  }
  
  componentWillUnmount() {
    this.setState({
      display: false
    })
  }
                
    render(){
        return (
          this.state.display ? 
            <div className={styles.Error}>
                <img src={error} alt="error"/>&nbsp;&nbsp;&nbsp;error occurred
            </div>
            : null
        )
    }
    
}
export default ErrorNotification;