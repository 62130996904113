import React, { Component } from 'react';
import cx from 'classnames';
import _ from 'lodash';

import eye from "../../../assets/icons/eye_close.svg";
import eyeOpen from "../../../assets/icons/Eye_open.svg";
import eyeDisable from "../../../assets/icons/eye_close_disable.svg";
import styles from './PasswordInput.module.scss';

class PasswordInput extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showPassword: false
        };
    }

    handleOnBlur = (event) => {
        this.handleCheckValidation(event.target.value);
    }

    handleOnChange = (event) => {
        this.props.onChange(event);
        if (!_.isEmpty(this.props.errors)) {
            this.handleCheckValidation(event.target.value);
        }
    }

    handleCheckValidation = (value) => {
        if (!_.isEmpty(this.props.validation)) {
            let errors = {};
            _.forEach(this.props.validation, function (validationFunction, rule) {
                if (!validationFunction(value)) {
                    errors[rule] = this.props.message[rule];
                }
            })
            this.props.onError(errors);
        }
    }

    handleShowPassword = () => {
        this.setState({
            showPassword: !this.state.showPassword
        })
    }

    getIcon = () => {
        if (this.props.value === '') {
            return eyeDisable
        } else {
            if (this.state.showPassword) {
                return eye
            } else return eyeOpen;
        }
    }

    render() {
        let errorList = [];
        if (!_.isEmpty(this.props.errors)) {
            _.forEach(this.props.errors, function (error, field) {
                errorList.push(
                    <div key={field} className={styles.ErrorMessage}>{error}</div>
                )
            })
        }

        return (
            <div className={cx(this.props.className, styles.Input)}>
                {
                    this.props.label ?
                        <div className={(this.props.value && this.props.value.length > 0) ? cx(styles.LabelWithValue) : cx(styles.Label)}>
                            {this.props.label} <span className={styles.requiredStar}>{this.props.required && '*'}</span>
                        </div>
                        :
                        null
                }
                <div className={cx(this.props.error ? styles.errorFocus : styles.focusStyle)}>
                    <input
                        className={styles.inputField}
                        type={this.state.showPassword ? 'text' : 'password'}
                        placeholder={this.props.placeholder}
                        disabled={this.props.disabled}
                        value={!_.isEmpty(this.props.value) ? this.props.value : ''}
                        onChange={(event) => this.handleOnChange(event)}
                        onPaste={(event) => this.handleOnChange(event)}
                        min="0"
                        autoComplete="off"
                        onBlur={this.props.onBlur}
                    />
                    <img src={this.getIcon()} alt="eye" onClick={() => this.handleShowPassword()} style={{ cursor: this.props.value !== '' ? "pointer" : "not-allowed" }} height="22px" className="my-auto"/>
                </div>
                {errorList}

            </div>
        );
    }
}
export default PasswordInput;