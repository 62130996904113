import * as actionTypes from './actionTypes';
import axios from 'axios';

const IDENTITY = process.env.REACT_APP_IDENTITY_API_URL;

export const initState = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.INIT_STATE
        })
    };
};

//post client data
export const postClientData = (data) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.POST_CLIENT_DATA_LOADING
        })
        let apiUrl = IDENTITY + '/client';
        axios.post(apiUrl,data)
            .then(response => {
                if (response.status === 200 || response.status === 201) {
                    dispatch({
                        type: actionTypes.POST_CLIENT_DATA_SUCCESS,
                        clientId: response.data
                    });
                }
            })
            .catch(error => {
                let errMsg  = error;
                if(error.response && error.response.data.errorMessage){
                    errMsg = error.response.data.errorMessage;
                }
                dispatch({
                    type: actionTypes.POST_CLIENT_DATA_ERROR,
                    error: errMsg
                });
            });
    };
}

//put client data
export const putClientData = (data,id ) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.PUT_CLIENT_DATA_LOADING
        })
        let apiUrl =  IDENTITY +'/client/' + id;
        axios.put(apiUrl,data)
            .then(response => {
                if (response.status === 200 || response.status === 201) {
                    dispatch({
                        type: actionTypes.PUT_CLIENT_DATA_SUCCESS,
                        clientData: response.data
                    });
                }
            })
            .catch(error => {
                let errMsg  = error;
                if(error.response && error.response.data.errorMessage){
                    errMsg = error.response.data.errorMessage;
                }
                dispatch({
                    type: actionTypes.PUT_CLIENT_DATA_ERROR,
                    error: errMsg
                });
            });
    };
}

//delete client 
export const deleteClient = (id ) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.DELETE_CLIENT_LOADING
        })
        let apiUrl =  IDENTITY + '/client/' + id;
        axios.delete(apiUrl)
            .then(response => {
                if (response.status === 200 || response.status === 201) {
                    dispatch({
                        type: actionTypes.DELETE_CLIENT_SUCCESS,
                        // clientData: response.data
                    });
                }
            })
            .catch(error => {
                let errMsg  = error;
                if(error.response && error.response.data.errorMessage){
                    errMsg = error.response.data.errorMessage;
                }
                dispatch({
                    type: actionTypes.DELETE_CLIENT_ERROR,
                    error: errMsg
                });
            });
    };
}
//get client list
export const getClientDetail = (clientId) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.GET_CLIENT_DETAIL_LOADING
        })
        let apiUrl = IDENTITY + '/client/' + clientId;
        
        axios.get(apiUrl)
        
            .then(response => {
                
                if (response.status === 200 || response.status === 201) {
                    
                    dispatch({
                        type: actionTypes.GET_CLIENT_DETAIL_SUCCESS,
                        clientDetail: response.data,
                        
                    });
                    
                }
            })
            .catch(error => {
                let errMsg  = error;
                if(error.response && error.response.data.errorMessage){
                    errMsg = error.response.data.errorMessage;
                }
                dispatch({
                    type: actionTypes.GET_CLIENT_DETAIL_ERROR,
                    error: errMsg
                });
            });
    };
}