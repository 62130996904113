import React from 'react';
import styles from './WarningPopUp.module.scss';
import cx from 'classnames';
import warn from "../../../assets/icons/warning.svg";

class WarningPopUp extends React.Component { 
    render () {
        return(
            <div className = {styles.backdrop} onClick={(event) => {event.stopPropagation();}}>
                <div className = {styles.Container}>
                    <div className = {styles.icon40Infocontainer}>
                        <img src={warn} alt="icon" /> 
                    </div>
                    <h3 className={cx(styles.popUpText,"pt-4")}>{this.props.warning}</h3>
                    <p className = {styles.Warning}>are you sure?</p>
                    
                    <div className={styles.ButtonRectangle}>
                        <button className = { styles.button } onClick={this.props.warningPopUp}>yes</button>
                        <button className = {styles.buttonCancel} onClick={this.props.closePopup}>no</button>
                    </div>
                    
                </div>
            </div>    
        );
    }
}

export default WarningPopUp;