import React, { Component } from 'react';
// import { createField, fieldPresets } from 'react-advanced-form';
import cx from 'classnames';
import styles from './Input.module.scss';
import themes from '../../../theme.scss';

class Input extends Component {
    state = {
        required: false,
        touch: false,
        isEdited : false 
    }
    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleBlur = (event) => {
        this.setState({ touch: true })
        if (event.target.value && event.target.value.length > 0) {
            if (this.props.required === false) {
                this.setState({
                    required: true
                });
            }
        }
        else {
            this.setState({
                required: false
            })
        }
        
    }

    handleChange = (event) => {
        
        this.props.changed(event);
        this.setState({touch : false})
        this.setState({isEdited : true})
    }


    render() {

        return (

            <div className={cx(this.props.className, styles.Input, 'd-flex flex-column-reverse my-1')}>

                <input
                    className={styles.InputElement}
                    type={this.props.type}
                    placeholder={this.props.placeholder}
                    disabled={this.props.disabled}
                    value={this.props.value || ''}
                    onChange={(event) => this.handleChange(event)}
                    onBlur={(event) => this.handleBlur(event)}
                    style={this.props.fixed ? { backgroundColor: themes.primaryBackground } : {}}
                    min="0"
                />
                {this.props.label ?
                    <label className={(this.props.value && this.props.value.length > 0) ? cx(styles.LabelWithValue) : cx(styles.Label)}>
                        {this.props.label}
                    </label>
                    : ''}
            </div>
        );
    }
}
export default Input;