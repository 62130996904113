import React from 'react';
import styles from './LoginErrorPage.module.scss';
import cx from 'classnames';
import Button from "../../../atoms/Button/Button";
import errorIcon from '../../../../assets/icons/newError404.png';
import Cookies from 'universal-cookie';

const ErrorPage = () => {
    let errorBody = null;

    errorBody = 
    <div className={cx('row d-flex flex-column', 'text-center', "mt-4")}>
        <h1 className={styles.h1}>oops...!! </h1>
        <span className={styles.p}>Your request can't be completed at this moment.</span>
    </div>

    const goToHomePage = () => {
        const cookies = new Cookies();
        cookies.remove("access_token");
        cookies.remove("refresh_token");
        window.location = process.env.REACT_APP_IDENTITY_URL;
    }

    return (
        <div className={cx('row col-12 no-gutters', styles.BackgroundImage)}>
            <div className='col-6 offset-3 mt-5'>
                
                <div className={cx('row', 'justify-content-center', 'mt-5')}>
                    <img src={errorIcon} alt='icon' />
                </div>
                {errorBody}
                <div className="row col-1 mx-auto justify-content-center mt-4" onClick={goToHomePage}>
                    <Button label="take me home" className={cx(styles.AddButton)} ></Button>
                </div>
            </div>
        </div>
    );
}

export default ErrorPage;
