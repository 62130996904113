export const INIT_STATE = 'INIT_RESOURCES_LIST_STATE' ;

export const GET_RESOURCES_LIST_LOADING = 'GET_RESOURCES_LIST_LOADING';
export const GET_RESOURCES_LIST_SUCCESS = 'GET_RESOURCES_LIST_SUCCESS';
export const GET_RESOURCES_LIST_ERROR = 'GET_RESOURCES_LIST_ERROR';

export const GET_RESOURCES_DATA_LOADING = 'GET_RESOURCES_DATA_LOADING';
export const GET_RESOURCES_DATA_SUCCESS = 'GET_RESOURCES_DATA_SUCCESS';
export const GET_RESOURCES_DATA_ERROR = 'GET_RESOURCES_DATA_ERROR';

export const POST_RESOURCES_LIST_LOADING = 'POST_RESOURCES_LIST_LOADING';
export const POST_RESOURCES_LIST_SUCCESS = 'POST_RESOURCES_LIST_SUCCESS';
export const POST_RESOURCES_LIST_ERROR = 'POST_RESOURCES_LIST_ERROR';

export const PUT_RESOURCES_LIST_LOADING = 'PUT_RESOURCES_LIST_LOADING';
export const PUT_RESOURCES_LIST_SUCCESS = 'PUT_RESOURCES_LIST_SUCCESS';
export const PUT_RESOURCES_LIST_ERROR = 'PUT_RESOURCES_LIST_ERROR';

export const DELETE_RESOURCES_LIST_LOADING = 'DELETE_RESOURCES_LIST_LOADING';
export const DELETE_RESOURCES_LIST_SUCCESS = 'DELETE_RESOURCES_LIST_SUCCESS';
export const DELETE_RESOURCES_LIST_ERROR = 'DELETE_RESOURCES_LIST_ERROR';

