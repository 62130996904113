import React, { Component } from "react";
import * as actions from "../Store/action";

import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

import styles from "./UserDetails.module.scss";
import LeftNavBar from "../../../components/atoms/LeftNavBar/LeftNavBar";
import _ from "lodash";
import arrowDown from '../../../assets/icons/form.svg';
import arrowUp from '../../../assets/icons/formChecked.svg';
import cx from "classnames";
import Button from '../../../components/atoms/Button/Button';
import arrowLeft from "../../../assets/icons/arrowLeft.svg";
import ErrorNotification from "../../../components/atoms/ErrorNotification/ErrorNotification";
import themes from "../../../theme.scss";


class UserDetails extends Component {

    state = {
        headerDropDown: false,
        openDrownIndex: -1,
        selectedBusinessFunctions: [],
        enableSubmit: false,
        tokenState: "ACTIVE",
    }

    componentDidMount() {
        let userId = this.props.match.params.userId;
        if (_.isEmpty(this.props.Users)) {
            this.props.onGetUsers();
        } else {
            let userData = this.props.Users.find(user => {
                return user._id === userId
            })
            const orgId = userData.orgId;
            this.props.onGetPolicy(userId, orgId);
            this.props.onGetPermissions();
            this.props.onGetToken(userId, this.state.tokenState);
        }
    }

    componentDidUpdate = (prevProps, prevState) => {

        if (prevProps.getPolicyState !== this.props.getPolicyState) {
            if (this.props.getPolicyState === 'SUCCESS') {
                let propsBusFunctions = [];
                if ((this.props.policyData) && (this.props.policyData.org[0]["businessFunctions"])) {
                    _.forEach(this.props.policyData.org, function (policy) {
                        propsBusFunctions = [...propsBusFunctions, ...policy.businessFunctions]
                    })
                }
                this.setState({
                    enableSubmit: false,
                    selectedBusinessFunctions: propsBusFunctions
                })
            }
        }

        if (prevProps.putPolicyDataState !== this.props.putPolicyDataState) {
            if (this.props.putPolicyDataState === 'SUCCESS') {
                let propsBusFunctions = [];
                _.forEach(this.props.policyData.org, function (policy) {
                    propsBusFunctions = [...propsBusFunctions, ...policy.businessFunctions]
                })
                this.setState({
                    enableSubmit: false,
                    selectedBusinessFunctions: propsBusFunctions
                })
            }
        }

        if (prevState.selectedBusinessFunctions !== this.state.selectedBusinessFunctions) {
            let propsBusFunctions = [];
            _.forEach(this.props.policyData.org, function (policy) {
                propsBusFunctions = [...propsBusFunctions, ...policy.businessFunctions]
            })

            if (_.isEqual(propsBusFunctions, this.state.selectedBusinessFunctions)) {
                this.setState({
                    enableSubmit: false
                })
            } else {
                this.setState({
                    enableSubmit: true
                })
            }
        }

        if (prevState.tokenState !== this.state.tokenState) {
            let userId = this.props.match.params.userId;
            this.props.onGetToken(userId, this.state.tokenState)
        }
    }

    componentWillUnmount = () => {
        this.props.initState();
    }

    handleDropDown = (index, bool) => {
        this.setState({
            headerDropDown: bool,
            openDrownIndex: index
        })
    }

    handleToggleCheckBox = (targetBusinessFunction) => {
        let updatedBusinessFunctions = _.cloneDeep(this.state.selectedBusinessFunctions);
        if (_.includes(updatedBusinessFunctions, targetBusinessFunction)) {
            updatedBusinessFunctions = updatedBusinessFunctions.filter(busFunction => {
                if (busFunction === targetBusinessFunction) return null;
                else return busFunction;
            })
        } else {
            updatedBusinessFunctions = [...updatedBusinessFunctions, targetBusinessFunction]
        }
        this.setState({
            selectedBusinessFunctions: updatedBusinessFunctions,
        })

    }

    handleSelectAll = (permissionIndex, selectAll) => {
        let updatedBusinessFunctions = _.cloneDeep(this.state.selectedBusinessFunctions);
        const thisRef = this;
        _.forEach(thisRef.props.permissionData[permissionIndex].modules, function (policymodule) {
            _.forEach(policymodule.businessFunctions, function (businessFunction) {
                if (selectAll && _.includes(thisRef.state.selectedBusinessFunctions, businessFunction.businessFunction)) {
                    updatedBusinessFunctions = updatedBusinessFunctions.filter(selectedFunction => {
                        if (selectedFunction === businessFunction.businessFunction) return null;
                        else return selectedFunction
                    })
                }
                else if (!_.includes(thisRef.state.selectedBusinessFunctions, businessFunction.businessFunction)) {
                    updatedBusinessFunctions = [businessFunction.businessFunction, ...updatedBusinessFunctions]
                }
            })
        })
        this.setState({
            selectedBusinessFunctions: updatedBusinessFunctions,
        })
    }

    handleShowSelectAll = (permissionIndex) => {
        let selectAll = false;
        let selectedBusinessFunctions = 0;
        let totalBusinessFunctions = 0;
        const thisRef = this;
        _.forEach(thisRef.props.permissionData[permissionIndex].modules, function (policymodule) {
            totalBusinessFunctions = totalBusinessFunctions + policymodule.businessFunctions.length
            _.forEach(policymodule.businessFunctions, function (businessFunction) {
                if (_.includes(thisRef.state.selectedBusinessFunctions, businessFunction.businessFunction)) {
                    selectedBusinessFunctions = selectedBusinessFunctions + 1;
                }
            })
        })
        if (selectedBusinessFunctions === totalBusinessFunctions) {
            selectAll = true;
        }
        return selectAll;
    }

    handleShowSelected = (targetBusinessFunction) => {
        let isSelected = false;
        _.forEach(this.props.policyData, function (policy) {
            if (policy.org) {
                _.forEach(policy.org, function (orgPolicy) {
                    if (_.includes(orgPolicy.businessFunction, targetBusinessFunction)) {
                        isSelected = true;
                    }
                })
            }
        })
        return (isSelected);
    }

    handleSavePolicy = (userId, orgId) => {
        let policyId = this.props.policyData._id;
        let policyPayload = {
            "userId": userId,
            "orgId": orgId,
            "org": [
                {
                    "orgId": orgId,
                    "businessFunctions": this.state.selectedBusinessFunctions
                }
            ]
        }
        this.props.onPutPolicy(policyPayload, policyId)
    }

    handleCancel = () => {
        let propsBusFunctions = [];
        _.forEach(this.props.policyData.org, function (policy) {
            propsBusFunctions = [...propsBusFunctions, ...policy.businessFunctions]
        })
        this.setState({
            selectedBusinessFunctions: propsBusFunctions
        })
    }

    handleUsers = (type) => {
        this.setState({
            tokenState: type
        })
    }

    handleGenerate = () => {
        let userId = this.props.match.params.userId;
        let userData = this.props.Users.find(user => {
            return user._id === userId
        })
        const generatePayload = {
            "grant_type": "client_credentials",
            "userId": userId,
            "clientId": userData.clientKey
        }
        this.props.onGenerateToken(generatePayload);
    }

    handleRevoke = () => {
        let userId = this.props.match.params.userId;
        let userData = this.props.Users.find(user => {
            return user._id === userId
        })
        const revokePayload = {
            userId: userId,
            clientKey: userData.clientKey
        }
        this.props.onPostRevoke(revokePayload)
    }

    shortenTagName = (tagName, label) => {
        if (label) {
            if (tagName.length > 22) {
                const updatedTagName = tagName.substring(0, 22) + '...';
                return (updatedTagName);
            }
            return (tagName);
        }
        else {
            if (tagName.length > 8) {
                const updatedTagName = tagName.substring(0, 8) + '...';
                return (updatedTagName);
            }
            return (tagName);
        }

    }

    render() {
        let userId = this.props.match.params.userId;
        let userData = this.props.Users.find(user => {
            return user._id === userId
        })
        return (
            <div className="row px-0">
                <div className="col-1 px-0">
                    <LeftNavBar />
                </div>

                <div className={cx("col-9  px-4 mt-4 py-0", styles.pageScroll, styles.scrollbar)}>
                    <Link to={"/users"} className={styles.back}><img src={arrowLeft} alt="arrow" className="mr-2" />users</Link>
                    <div className={styles.pageHead}>user details</div>
                    <div className={cx(styles.Card)}>
                        {this.props.putPolicyDataState === "ERROR" ? <ErrorNotification /> : null}
                        <div className="pb-3 pt-2 px-4 row">
                            {!_.isEmpty(userData) ?
                                <div className="ml-3">
                                    <div className={styles.Heading}>{userData.userName}</div>
                                    <div>org Id: {userData.orgId}</div>
                                </div>
                                : null}

                            <div className={cx("px-4", styles.actionButonAlign)} onClick={() => this.handleRevoke()}>
                                <span className={styles.revokeButton} >REVOKE</span>
                            </div>
                        </div>
                    </div>

                    <div className={styles.pageHead}>tokens</div>
                    <div className={cx(styles.Card)}>
                        <div className="pb-4 pt-4 px-4">
                            <ul className="row pl-2">
                                <div className={cx("px-4", this.state.tokenState === "ACTIVE" ? styles.TabActive : styles.Tab, styles.TabsHover)} onClick={() => this.handleUsers("ACTIVE")}>ACTIVE</div>
                                <div className={cx("px-4", this.state.tokenState === "INACTIVE" ? styles.TabActive : styles.Tab, styles.TabsHover)} onClick={() => this.handleUsers("INACTIVE")}>INACTIVE</div>

                                <div className={!_.isEmpty(this.props.tokenData) || this.state.tokenState === "INACTIVE" ? styles.generateButtonDisabled : styles.generateButton}
                                    onClick={!_.isEmpty(this.props.tokenData) || this.state.tokenState === "INACTIVE" ? null : () => this.handleGenerate()}>GENERATE</div>
                            </ul>

                            <div>
                                <div className={cx("row mt-4", styles.tokenCard, styles.scrollbar)}>
                                    {!_.isEmpty(this.props.tokenData) ?
                                        this.props.tokenData.map((token, index) => {
                                            return (
                                                <div className="col-6" key={index}>
                                                    <div className={styles.tokenBg}>
                                                        <div>token : <span className={styles.tokenText}>{token.token}</span></div>
                                                        <div>token type : <span className={styles.tokenText}>{token.type.replace(/_/gi, " ")}</span></div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        <React.Fragment>
                                            <div className={cx(styles.nullWarn, "px-4")}>"there are no tokens to show"</div>
                                        </React.Fragment>
                                    }

                                </div>
                            </div>

                        </div>
                    </div>

                    <div className={cx(styles.pageHead)}>policy</div>
                    <div className={cx(styles.Card, "mb-5")}>
                        <div className="pb-4 pt-4">
                            <div className={cx(styles.saveAlign, "px-0 ml-auto")}>
                                <Button label="save" clickHandler={() => this.handleSavePolicy(userId, userData.orgId)} isDisabled={!this.state.enableSubmit} />
                                <Button label="cancel" clickHandler={() => this.handleCancel()} type="cancel" className="ml-3" />
                            </div>
                            <div className={cx(styles.CardScroll, "pl-5 pr-0")}>
                                {this.props.permissionData.map((permission, permissionIndex) => {

                                    const selectAll = this.handleShowSelectAll(permissionIndex);
                                    return (
                                        <React.Fragment key={permissionIndex}>

                                            <div>
                                                <div className='d-flex flex-row row ml-1 my-3' >
                                                    <div className={permission.dropDown ? cx(styles.group7, "pr-0") : cx(styles.group8, "pr-0")}>
                                                        {this.state.headerDropDown && this.state.openDrownIndex === permissionIndex ?
                                                            <React.Fragment><img src={arrowUp} alt={"arrow up"} onClick={() => this.handleDropDown(permissionIndex, false)} className={cx('mr-3 mb-1', styles.hoverPoint)} />
                                                                    &nbsp;&nbsp;<span style={{ "color": "grey" }}>{permission.serviceLabel}</span>
                                                            </React.Fragment>
                                                            :
                                                            <React.Fragment><img src={arrowDown} alt={"arrow down"} onClick={() => this.handleDropDown(permissionIndex, true)} className={cx('mr-3 mb-1', styles.hoverPoint)} />
                                                                    &nbsp;&nbsp;{permission.serviceLabel}
                                                            </React.Fragment>}

                                                    </div>
                                                    <div className={cx(styles.optionsContainer, "d-flex mb-3")}>

                                                        <div className={cx(styles.OptionsPadding)} style={{ position: "relative" }}>
                                                            <label className={cx(styles.Container)}>
                                                                <input
                                                                    type='checkbox'
                                                                    onChange={() => this.handleSelectAll(permissionIndex, selectAll)}
                                                                    checked={selectAll}
                                                                    className={cx(styles.hoverPoint, styles.CheckBox)}
                                                                />
                                                                <span className={cx(styles.Checkmark)}></span>
                                                            </label>
                                                            <span className="ml-4" style={{ "color": "grey" }}>all</span>
                                                        </div>

                                                    </div>
                                                </div>
                                                {this.state.headerDropDown && this.state.openDrownIndex === permissionIndex ?
                                                    permission.modules.map((moduleData, serviceIndex) => {
                                                        return (
                                                            <div key={serviceIndex} className=' row ml-2' id='toggle'>
                                                                <div className={cx(styles.onCheck)}>
                                                                    <ol className={styles.a}><li className={cx(styles.toggleText, "text-nowrap")}> <span> • &nbsp;</span> 
                                                                    <div className={cx(styles.tooltip, "ml-4")} >
                                                                        {this.shortenTagName(moduleData.moduleLabel, "label")}
                                                                        <span className={cx(styles.tooltiptext)}>{moduleData.moduleLabel}</span>
                                                                    </div>
                                                                    </li></ol>
                                                                </div>
                                                                <div className={cx(styles.optionsContainer, "col-8 row mb-2")}>
                                                                    {moduleData.businessFunctions.map((businessFunction, businessIndex) => {

                                                                        const isSelected = _.includes(this.state.selectedBusinessFunctions, businessFunction.businessFunction);
                                                                        return (
                                                                            <React.Fragment key={businessIndex}>

                                                                                <span className={cx("col-3 mt-2 pr-0 text-nowrap")}>
                                                                                    <label className={cx(styles.Container)}>
                                                                                        <input
                                                                                            type='checkbox'
                                                                                            onChange={() => this.handleToggleCheckBox(businessFunction.businessFunction)}
                                                                                            checked={isSelected}
                                                                                            className={cx(styles.hoverPoint, styles.CheckBox)}
                                                                                        />
                                                                                        <span className={cx(styles.Checkmark)}></span>
                                                                                    </label>
                                                                                    <div className={cx(styles.tooltip, "ml-4")} style={isSelected ? { "color": themes.primaryLabel } : null}>
                                                                                        {this.shortenTagName(businessFunction.functionLabel)}
                                                                                        <span className={cx(styles.tooltiptext)}>{businessFunction.functionLabel}</span>
                                                                                    </div>
                                                                                </span>

                                                                            </React.Fragment>)
                                                                    })}

                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                    : null}

                                            </div>

                                        </React.Fragment>
                                    )
                                })}
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = state => {
    return {
        Users: state.user.userList,
        permissionData: state.user.permissionData,
        policyData: state.user.policyData,
        tokenData: state.user.TokenData,
        getPolicyState: state.user.getPolicyState,
        putPolicyDataState: state.user.putPolicyDataState
    }
};

const mapDispatchToProps = dispatch => {
    return {
        initState: () => dispatch(actions.initState()),
        onGetUsers: () => dispatch(actions.getUsers()),
        onGetPermissions: () => dispatch(actions.getPermissions()),
        onGetPolicy: (userId, orgId) => dispatch(actions.getPolicy(userId, orgId)),
        onPutPolicy: (data, policyId) => dispatch(actions.putPolicyData(data, policyId)),
        onGetToken: (userId, type) => dispatch(actions.getToken(userId, type)),
        onGenerateToken: (data) => dispatch(actions.generateToken(data)),
        onPostRevoke: (data) => dispatch(actions.postRevoke(data)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserDetails));