import React, { Component } from 'react';

import { NavLink } from "react-router-dom";
import { withRouter } from "react-router";

import cx from "classnames";
import styles from "./ClientHome.module.scss";
import LeftNavBar from '../../../components/atoms/LeftNavBar/LeftNavBar';

class ClientHome extends Component {
    render() {
        return (
            <div className="row">
                <div className={cx("col-1 px-0", styles.leftNav)}>
                    <LeftNavBar />
                    
                </div>
                <div className={"col-9 pt-4"}>
                welcome
                <div className="mt-3">
                <NavLink to="/clients"
                className={styles.NavLinkfont}
                // activeClassName={styles.NavLinkfontActive}
                >
                    go to clients
                </NavLink>
                </div>
                </div>
                
            </div>
        )
    }
}

export default withRouter(ClientHome);