import React, { Component } from "react";
import _ from "lodash";
import Input from "../../../components/atoms/Input/Input";
import DropDown from "../../../components/atoms/DropDown/DropDown";
import styles from "./ResourceDetails.module.scss";
import close from '../../../assets/icons/closeNotification.svg';
import addMore from '../../../assets/icons/addMore.svg';

class BusinessFunction extends Component {

    state = {
        businessFunc: {
            businessFunction: '',
            functionLabel: '',
            channel: ''
        },
        businessFunctions: []
    }

    componentDidMount() {
        let arr = _.cloneDeep(this.props.businessFunctionData)
        this.setState({
            businessFunctions: arr
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.businessFunctionData !== this.props.businessFunctionData) {
            let arr = _.cloneDeep(this.props.businessFunctionData)
            this.setState({
                businessFunctions: arr
            })
        }
    }

    handleInputChange = (event, inputIdentifier) => {
        let updatedFormData = { ...this.state.businessFunc }
        if (inputIdentifier === "channel") {
            updatedFormData[inputIdentifier] = event
        } else {
            updatedFormData[inputIdentifier] = event.target ? event.target.value : event.value;
        }
        // updatedFormData = { ...updatedFormData, [inputIdentifier]: event.target ? event.target.value : event.value }
        this.setState({ businessFunc: updatedFormData });
    }

    handleAddFunction = (event) => {
        event.preventDefault();
        if (!_.isEmpty(this.state.businessFunc)) {
            let func = _.cloneDeep(this.state.businessFunc);
            let arr = _.cloneDeep(this.state.businessFunctions);
            arr = [...arr, func];
            this.props.changed(arr)
            this.setState({
                businessFunctions: arr,
                businessFunc: {
                    businessFunction: '',
                    functionLabel: '',
                    channel: ''
                }
            })
        }
    }

    handleDeleteFunction = (data) =>{
        let filter = {
            businessFunction : data.businessFunction,
            functionLabel : data.functionLabel,
            channel : data.channel
        } 
        let arr = _.cloneDeep(this.state.businessFunctions);
        arr= arr.filter(function(item) {
            for (let key in filter) {
              if (item[key] === undefined || item[key] !== filter[key])
                return true;
            }
            return false;
        });
        this.props.changed(arr)
        this.setState({
            businessFunctions : arr
        })
    }



    render() {
        return (

            <div>
                <div className="row pl-2">
                    <Input
                        name="functionLabel"
                        type='text'
                        placeholder="Function Label"
                        value={this.state.businessFunc.functionLabel}
                        changed={(event) => this.handleInputChange(event, 'functionLabel')}
                        className="col-4 pr-3"
                        label="Function Label"
                        required
                    />
                    <Input
                        name="businessFunction"
                        type='text'
                        placeholder="Business Function"
                        value={this.state.businessFunc.businessFunction}
                        changed={(event) => this.handleInputChange(event, 'businessFunction')}
                        className="col-4 pr-3"
                        label="Business Function"
                        required
                    />
                    <div style={{marginTop:"0.75rem"}}>
                        <DropDown
                            Options={[{ "option": "PORTAL", "optionLabel": "portal" },
                            { "option": "APP", "optionLabel": "app" },
                            { "option": "THIRD_PARTY", "optionLabel": "third party" }
                            ]}
                            changed={(value) => this.handleInputChange(value, 'channel')}
                            value={this.state.businessFunc.channel}
                            label="Select Channel"
                            className={styles.dropDown}
                        />
                        </div>
                    
                    { !_.isEmpty(this.state.businessFunc["businessFunction"]) && !_.isEmpty(this.state.businessFunc["functionLabel"]) 
                    && !_.isEmpty(this.state.businessFunc["channel"])?
                    <span onClick={(event) => this.handleAddFunction(event)} className="mt-4 pt-3 ml-4" style={{cursor:"pointer"}}>
                        <img src={addMore} className={"ml-auto"} alt="add more" /> 
                    </span> : null}
                    
                </div>

                {this.state.businessFunctions !== undefined ?
                    <div>
                        {this.state.businessFunctions.map((dt, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <div className={styles.funcBg}>
                                        function label : <span className={styles.func}>{dt.functionLabel}</span>&nbsp;&nbsp;
                                        function  : <span className={styles.func}>{dt.businessFunction}</span>&nbsp;&nbsp;
                                        channnel : <span className={styles.func}>{dt.channel}</span>
                                        <span onClick={() => this.handleDeleteFunction(dt)} style={{cursor:"pointer",marginLeft:'2rem'}}><img src={close} alt="close" /></span>
                                    </div>
                                </React.Fragment>
                            )
                        })}
                    </div> : null}
            </div>
        )
    }
}

export default BusinessFunction;