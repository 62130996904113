import React, { Component } from "react";
import styles from './DropDown.module.scss';
import _ from "lodash";
import cx from "classnames";
import arrowUp from "../../../assets/icons/arrowUp.svg";
import arrowDown from "../../../assets/icons/arrowDown.svg";


class DropDown extends Component {
    container = React.createRef();

    state = {
        toggleDropdown: false,
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }
    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleToggleDropdown = () => {
        this.setState({
            toggleDropdown: !this.state.toggleDropdown
        })
    }


    handleClient = (name) => {
        var newOption = _.filter(this.props.Options, function (op) {
            return op.optionLabel === name;
        });
        let optionVal = newOption[0].option;
        this.props.changed(optionVal)
        this.setState({
            toggleDropdown: false,

        })
    }


    getLabel = (val) => {
        
        var newOption = _.filter(this.props.Options, function (op) {
            return op.option === val;
        });
        return newOption[0].optionLabel;
    }
       
    

    handleClickOutside = event => {
        if (this.container.current && !this.container.current.contains(event.target)) {
            this.setState({
                toggleDropdown: false,
            });
        }
    };

    render() {

        return (
            <React.Fragment>
                <div style={{ position: "relative",minWidth:"14rem" }}>
                    <div className={styles.SearchBy}>{this.props.label}</div> 
                  {this.state.toggleDropdown ?
                  <div className={styles.searchDropdown}>
                    <div onClick={this.handleToggleDropdown}>
                      <label className={cx(styles.dropdownHead,'mb-0 px-3 py-2')}>{this.props.label}</label>
                      <img src={arrowUp} className={styles.iconUp} align='right' alt='arrow up'/>
                      <br/>
                     
                    </div>
                    <hr className={styles.HorizontalLine} />
                      {this.props.Options.map((option, index) => {
                        return (
                          <div key={index}>
                            <label className={cx('mb-0', styles.Options)} onClick={() => this.handleClient(option.optionLabel)} ref={dropDownDiv => this.dropDownDiv = dropDownDiv}> {option.optionLabel}</label>
                          </div>
                        )} ) 
                    }
                  </div>
                  :
                  <div className={styles.SearchOption} onClick={this.handleToggleDropdown}>
                      {this.props.value !== '' ?
                    <label className='mb-0' style={{cursor: 'pointer'}}> {this.getLabel(this.props.value)}</label>
                    : <span>select option</span>}
                    <img src={arrowDown} className={styles.DropdownIcon} align='right' alt='' />
                  </div>
                }
              </div>
            </React.Fragment>

        )
    }
}

export default DropDown;