import React, { Component } from "react";
import { withRouter } from "react-router";
import { Route } from "react-router-dom";

import cx from "classnames";
import styles from './Home.module.scss';

import ClientMgmt from "../ClientMgmt/ClientMgmt";

class Home extends Component {
    render(){
        return(
            <div className="row px-0 mx-0">
                
                <div className={cx("col-12",styles.content)}>
                    <Route path='/'  component={ClientMgmt} />
                </div>
            </div>
        )

    }    
}


export default withRouter(Home);