import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router";
import * as actions from "../Store/action";
import { Link } from "react-router-dom";
import styles from "./ResourceDetails.module.scss";
import LeftNavBar from "../../../components/atoms/LeftNavBar/LeftNavBar";
import arrowLeft from "../../../assets/icons/arrowLeft.svg";
import Input from '../../../components/atoms/Input/Input';
import Button from "../../../components/atoms/Button/Button";
import cx from "classnames";
import MultipleInputKeyValue from '../../../components/molecules/MultipleInput/MultipleInputKeyValue';
import _ from "lodash";
import BusinessFunction from "./BusinessFunction";
import WarningPopUp from "../../../components/molecules/WarningPopUp/WarningPopUp";
import DropDown from "../../../components/atoms/DropDown/DropDown";


class ResourceDetails extends Component {

    state = {
        formData: {
            service: "",
            serviceLabel: "",
            module: "",
            moduleLabel: "",
            businessFunctions: [],
            scope: '',
            resource: "",
            method: "",
            params: {},
            visibility: "VISIBLE"
        },
        enableSubmit: false,
        typeAdd: false,
        showPopUp: false
    }

    componentDidMount() {
        let differentiator = this.props.match.url.split('/').pop();
        let add = true;
        if (differentiator !== "add") {
            this.props.getResourceData(differentiator)
            add = false;
        } else {
            add = true;
        }
        this.setState({
            typeAdd: add
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.getResourceDataState !== this.props.getResourceDataState) {
            if (this.props.getResourceDataState === "SUCCESS") {
                this.setState({
                    formData: this.props.resourceData,
                    enableSubmit: false
                })
            }
        }
        if (prevProps.postResourceState !== this.props.postResourceState) {
            if (this.props.postResourceState === "SUCCESS") {
                this.setState({
                    typeAdd: false,
                    enableSubmit: false
                })
            }
        }

        if (prevProps.putResourceState !== this.props.putResourceState) {
            if (this.props.putResourceState === "SUCCESS") {
                this.setState({
                    enableSubmit: false
                })
            }
        }
    }

    componentWillUnmount() {
        this.props.initState()
    }

    handleInputChange = (event, inputIdentifier) => {
        let updatedFormData = _.cloneDeep(this.state.formData)
        if (inputIdentifier === "businessFunctions") {
            updatedFormData = { ...updatedFormData, [inputIdentifier]: event }
        }
        else if (inputIdentifier === "scope" || inputIdentifier === "method" || inputIdentifier === "visibility") {
            updatedFormData[inputIdentifier] = event
        } else {
            updatedFormData = { ...updatedFormData, [inputIdentifier]: event.target ? event.target.value : event.value }
        }
        this.setState({ formData: updatedFormData, enableSubmit: true });
    }

    toggleSave = () => {
        let differentiator = this.props.match.url.split('/').pop();
        if (differentiator !== "add") {
            this.props.putResource(this.state.formData)
        } else {
            this.props.postResource(this.state.formData)
        }
    }

    togglePopUp = () => {
        let showDeletePopUpFlag = (this.state.showPopUp ? false : true)
        this.setState({
            showPopUp: showDeletePopUpFlag
        });
    }

    deleteData = () => {
        this.setState({
            showPopUp: false
        })
        this.props.deleteResource(this.state.formData._id)
    }

    handleParamsChange = (keys, value, inputIdentifier, type) => {
        let updatedParamsobj = _.cloneDeep(this.state.formData.params)
        let updatedFormArray = _.cloneDeep(this.state.formData)
        let newParams = {
            [keys]: value
        }
        if (type === 'add') {
            let newInput = Object.assign({},
                updatedParamsobj, newParams);
            updatedFormArray[inputIdentifier] = newInput
        }
        else if (type === 'delete') {
            delete updatedParamsobj[keys];
            updatedFormArray[inputIdentifier] = updatedParamsobj
        }
        this.setState({
            enableSubmit: true,
            formData: updatedFormArray
        })

    }

    render() {
        return (
            <React.Fragment>
                {this.props.postResourceState === "SUCCESS" ? <Redirect to={"/resource/" + this.props.resourceId} /> : null}
                {this.state.showPopUp ?
                    <WarningPopUp
                        warningPopUp={() => this.deleteData()}
                        closePopup={this.togglePopUp.bind(this)}
                        warning="delete ?" />
                    : null}
                <div className="row">
                    <div className="col-1 px-0">
                        <LeftNavBar />
                    </div>

                    <div className="col-9 mt-4 mx-3">
                        <Link to={"/resource"} className={styles.back}><img src={arrowLeft} alt="arrow" className="mr-2" />resources</Link>
                        <div className={styles.pageHead}>resource details</div>
                        <br />
                        <div className={cx(styles.form, styles.scrollbar)}>
                            <div >
                                <form>
                                    <div className={cx(this.state.focus ? styles.InputPlace : styles.InputPlaceBlur)}>
                                        <div className='row no-gutters'>
                                            <Input
                                                name="resource"
                                                type='text'
                                                placeholder="Resource"
                                                value={this.state.formData.resource}
                                                changed={(event) => this.handleInputChange(event, 'resource')}
                                                className="col-6 pr-3"
                                                label="Resource"
                                                required
                                            />
                                            <div style={{ marginTop: "0.75rem" }}>
                                                <DropDown
                                                    Options={[
                                                        { "option": "GET", "optionLabel": "GET" },
                                                        { "option": "POST", "optionLabel": "POST" },
                                                        { "option": "PUT", "optionLabel": "PUT" },
                                                        { "option": "DELETE", "optionLabel": "DELETE" }
                                                    ]}
                                                    changed={(event) => this.handleInputChange(event, 'method')}
                                                    value={this.state.formData.method}
                                                    label="Select Method"
                                                />
                                            </div>
                                            <div style={{ marginTop: "0.75rem" }}>
                                                <DropDown
                                                    Options={[
                                                        { "option": "ORG", "optionLabel": "ORG" },
                                                        { "option": "LOC", "optionLabel": "LOC" },
                                                        { "option": "PLATFORM", "optionLabel": "PLATFORM" }
                                                    ]}
                                                    changed={(event) => this.handleInputChange(event, 'scope')}
                                                    value={this.state.formData.scope}
                                                    label="Select Scope"
                                                />
                                            </div>
                                        </div>
                                        <hr className={styles.hr} />
                                        <div className='row no-gutters'>
                                            <Input
                                                name="serviceLabel"
                                                type='text'
                                                placeholder="Service Label"
                                                value={this.state.formData.serviceLabel}
                                                changed={(event) => this.handleInputChange(event, 'serviceLabel')}
                                                className="col-4 pr-3"
                                                label="Service Label"
                                                required
                                            />
                                            <Input
                                                name="service"
                                                type='text'
                                                placeholder="Service"
                                                value={this.state.formData.service}
                                                changed={(event) => this.handleInputChange(event, 'service')}
                                                className="col-4 pr-3"
                                                label="Service"
                                                required
                                            />
                                        </div>
                                        <hr className={styles.hr} />
                                        <div className='row no-gutters'>
                                            <Input
                                                name="moduleLabel"
                                                type='text'
                                                placeholder="Module Label"
                                                value={this.state.formData.moduleLabel}
                                                changed={(event) => this.handleInputChange(event, 'moduleLabel')}
                                                className="col-4 pr-3"
                                                label="Module Label"
                                                required
                                            />
                                            <Input
                                                name="module"
                                                type='text'
                                                placeholder="Module"
                                                value={this.state.formData.module}
                                                changed={(event) => this.handleInputChange(event, 'module')}
                                                className="col-4 pr-3"
                                                label="Module"
                                                required
                                            />

                                        </div>
                                        <hr className={styles.hr} />
                                        <div>
                                            {this.state.formData.businessFunctions !== null ?
                                                <BusinessFunction
                                                    businessFunctionData={this.state.formData.businessFunctions}
                                                    changed={(data) => this.handleInputChange(data, 'businessFunctions')}
                                                />
                                                : null}
                                        </div>
                                        <hr className={styles.hr} />
                                        <MultipleInputKeyValue
                                            changed={(key, value, type) => this.handleParamsChange(key, value, 'params', type)}
                                            UriObj={this.state.formData.params ? this.state.formData.params : {}}
                                            name="params"
                                            label="Params"
                                            placeholder="Params"
                                        />
                                        <hr className={styles.hr} />
                                        <div className='row no-gutters'>
                                            <DropDown
                                                Options={[
                                                    { "option": "VISIBLE", "optionLabel": "visible" },
                                                    { "option": "HIDDEN", "optionLabel": "hidden" }
                                                ]}
                                                changed={(event) => this.handleInputChange(event, 'visibility')}
                                                value={ !_.isEmpty(this.state.formData.visibility)? this.state.formData.visibility : 'VISIBLE'}
                                                label="Select Visibility"
                                            />
                                        </div>
                                    </div>
                                </form>
                                <div className={styles.alignButton}>
                                    {
                                        !this.state.typeAdd ?
                                            <Button type="delete" label="delete" clickHandler={() => this.togglePopUp()} />
                                            : null}
                                    &nbsp;&nbsp;
                                    <Button label="save" clickHandler={() => this.toggleSave()} isDisabled={!this.state.enableSubmit} />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        getResourceDataState: state.resource.getResourceDataState,
        postResourceState: state.resource.postResourceState,
        putResourceState: state.resource.putResourceState,
        // deleteResourceState: state.resource.deleteResourceState,
        resourceId: state.resource.resourceId,
        resourceData: state.resource.resourceData
    };
};

const mapDispatchToProps = dispatch => {
    return {
        initState: () => dispatch(actions.initState()),
        getResourceData: (Id) => dispatch(actions.getResourceData(Id)),
        postResource: (data) => dispatch(actions.postResource(data)),
        putResource: (data) => dispatch(actions.putResource(data)),
        deleteResource: (id) => dispatch(actions.deleteResource(id))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResourceDetails));