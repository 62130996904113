import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import _ from 'lodash';
import * as actions from "./Store/action";
import styles from "./ResetPassword.module.scss";
import cx from "classnames";
import BPlogo from "../../assets/icons/betterPlaceNewLogo.svg";
import text from "../../assets/icons/text.svg";
import PasswordInput from '../../components/atoms/PasswordInput/PasswordInput';
import dots from "../../assets/icons/dots.svg";
class ResetPassword extends Component {

    state = {
        formData: {
            oldPassword: '',
            newPassword: '',
            confirmPassword: ''
        },
        enableNext: false,
        enableInput: false,
        regExMatch: false,
        samePassword: false,
        errorMessage: '',
        showError: false
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(prevState.formData, this.state.formData)) {
            const regEx = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
            const formData = _.cloneDeep(this.state.formData);
            let enableInput = false;
            let errorMessage = '';
            let showError = false;
            let enableNext = false;
            let samePassword = false;
            if (formData.oldPassword.length !== 0) {
                enableInput = true;
                if (_.isEqual(formData.oldPassword, formData.newPassword)) {
                    errorMessage = "new password can not be same as old.";
                }
                else {
                    showError = false;
                    if ((!regEx.test(formData.newPassword) && (formData.newPassword.length !== 0))) {
                        errorMessage = "password doesn't match the required pattern.";
                    } else {
                        showError = false;
                        if ((formData.newPassword !== formData.confirmPassword) && (formData.confirmPassword.length !== 0)
                            && (formData.newPassword.length !== 0)) {
                            errorMessage = "new password and confirm password doesn't match.";
                            samePassword = true;
                        }
                        else if ((formData.newPassword === formData.confirmPassword) && (formData.confirmPassword.length !== 0)) {
                            enableNext = true;
                            samePassword = false;
                        }
                        else {
                            showError = false;
                        }
                    }
                }
            }
            this.setState({
                enableInput: enableInput,
                errorMessage: errorMessage,
                showError: showError,
                enableNext: enableNext,
                samePassword: samePassword
            })
        }

        if (prevProps.postResetDataState !== this.props.postResetDataState) {
            if (this.props.postResetDataState === "ERROR") {
                this.setState({
                    showError: true
                })
            }
            if (this.props.postResetDataState === "SUCCESS") {
                const redirectURI = this.props.responseData['redirectURI'];
                window.location = redirectURI;
            }
        }

    }

    handleInputChange = (event, inputIdentifier) => {

        let updatedFormData = {
            ...this.state.formData,
        }
        updatedFormData[inputIdentifier] = event.target ? event.target.value : event.value;
        this.setState({
            formData: updatedFormData,
        });
    }

    handleShowError = () => {
        if (!this.state.samePassword) {
            this.setState({
                showError: true
            })
        }

    }

    handleNext = (event) => {
        event.preventDefault();
        let data = {
            "oldPassword": this.state.formData.oldPassword,
            "newPassword": this.state.formData.newPassword
        }
        this.props.onPostData(data);
    }

    handleRedirect = () => {
        const params = new URLSearchParams(window.location.search);
        if (params.has('defaultURI')) {
            window.location = params.get('defaultURI');
        } else {
            window.location = process.env.REACT_APP_IDENTITY_URL;
        }
    }

    render() {
        return (
            <div className={styles.container}>
                <div className={cx(styles.scrollbar, styles.pageContainer)}>
                    <div className={styles.alignDots}><img src={dots} alt="betterplace" height="66px" /></div>
                    <div style={{ marginBottom: '2px', marginLeft: '20px', marginTop: '50px' }}>
                        <img src={BPlogo} alt="bplogo" height="40px" />
                    </div>
                    <div className={styles.loginHeadBold}> betterplace</div>
                    <div className={styles.Heading}>change your password</div>
                    <form>
                        <div className={cx(styles.inputs)}>
                            <span className={styles.label}>current password</span><br />
                            <PasswordInput
                                placeholder="enter current password"
                                value={this.state.formData.oldPassword}
                                onChange={(event) => this.handleInputChange(event, 'oldPassword')}
                                className="mt-2"
                                name="oldPassword"
                            />
                            <br />
                            <Link to={"/forgot-password"} className={styles.labelRight}>forgot current password?</Link><br />
                        </div>
                        <hr className={styles.hr} />

                        <div className="d-flex">
                            <div><img src={text} alt="text" /></div>
                            <div className={styles.infoText}>password should have minimum 8 characters and must include 1 upper case, 1 lower case, 1 digit and a special character from '!@#$%^&*'</div>
                        </div>


                        <div className={cx(styles.inputs)}>
                            <span className={styles.label}>new password</span><br />
                            <PasswordInput
                                placeholder="enter new password"
                                value={this.state.formData.newPassword}
                                onChange={(event) => this.handleInputChange(event, 'newPassword')}
                                className="mt-2"
                                name="newPassword"
                                disabled={!this.state.enableInput}
                                onBlur={() => this.handleShowError()}
                                error={this.state.showError && this.state.errorMessage}
                            />
                            {this.state.showError && this.state.errorMessage !== '' &&
                                <div className={styles.errorContainer}>
                                    <span className={styles.errorText}>{this.state.errorMessage}</span>
                                </div>}
                        </div>
                        <div className={cx("mt-4")}>
                            <span className={styles.label}>confirm password</span><br />
                            <PasswordInput
                                name="confirmPassword"
                                placeholder="re-enter password"
                                onChange={(event) => this.handleInputChange(event, 'confirmPassword')}
                                value={this.state.formData.confirmPassword}
                                disabled={!this.state.enableInput}
                                className="mt-2"
                                error={this.state.samePassword && this.state.showError}
                            />
                            {this.state.samePassword &&
                                <div className={styles.errorContainer}>
                                    <span className={styles.errorText}>{this.state.errorMessage}</span>
                                </div>
                            }
                        </div>
                        <div className={styles.AlignButton}>
                            <button
                                onClick={(event) => this.handleNext(event)}
                                disabled={!this.state.enableNext}
                                className={cx(this.state.enableNext ? styles.buttonNextEnable : styles.buttonNextDisable,
                                    styles.buttonNext)}>
                                <span className={styles.nextText}>set password</span>
                            </button>
                            <div onClick={() => this.handleRedirect()} className={styles.NavLinkfont}>cancel</div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        postResetDataState: state.reset.postResetDataState,
        responseData: state.reset.responseData
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onPostData: (data) => dispatch(actions.postData(data)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPassword));