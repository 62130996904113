import * as actionTypes from './actionTypes';
import { updateObject } from './utility';

const initialState = {
    postLoginDataState: 'INIT',
    postRequestOTPState: 'INIT',
    loginData: {},
    waitingTime: null,
}


//INIT STATE REDUCER
const initState = () => {
    return initialState;
}

//LOGIN DATA STATE
const postLoginDataLoading = (state) => {
    return updateObject(state, {
        postLoginDataState: 'LOADING'
    });
}

const postLoginDataSuccess = (state, action) => {
    return updateObject(state, {
        postLoginDataState:'SUCCESS',
        loginData: action.loginData,
        error: null
    });
};

const postLoginDataError = (state, action) => {
    return updateObject(state, {
        postLoginDataState: 'ERROR',
        error: action.error,
    });
};

//REUEST OTP
const postRequestOTPLoading = (state) => {
    return updateObject(state, {
        postRequestOTPState: 'LOADING'
    });
}

const postRequestOTPSuccess = (state, action) => {
    return updateObject(state, {
        postRequestOTPState:'SUCCESS',
        waitingTime: action.waitingTime.RESEND_OTP_WAIT_TIME,
        error: null
    });
};

const postRequestOTPError = (state, action) => {
    return updateObject(state, {
        postRequestOTPState: 'ERROR',
        error: action.error,
    });
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INIT_STATE: return initState();

        case actionTypes.POST_LOGIN_DATA_LOADING: return postLoginDataLoading(state);
        case actionTypes.POST_LOGIN_DATA_SUCCESS: return postLoginDataSuccess(state, action);
        case actionTypes.POST_LOGIN_DATA_ERROR: return postLoginDataError(state, action);

        case actionTypes.POST_REQUEST_OTP_LOADING: return postRequestOTPLoading(state);
        case actionTypes.POST_REQUEST_OTP_SUCCESS: return postRequestOTPSuccess(state, action);
        case actionTypes.POST_REQUEST_OTP_ERROR: return postRequestOTPError(state, action);

        default: return state;
    }
};

export default reducer;

