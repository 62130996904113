import React, { Component } from 'react';

import styles from "./ForgotPassword.module.scss";
import success from "../../assets/icons/success.svg";
import arrow from "../../assets/icons/arrow-right.svg"

class ForgotPasswordSuccess extends Component {

    handleRedirect = () => {
        const params = new URLSearchParams(window.location.search);
        if (params.has('defaultURI')) {
            window.location = params.get('defaultURI');
        } else {
            window.location = process.env.REACT_APP_IDENTITY_URL;
        }
    }

    render() {
        return (
            <div className={(styles.Container)}>
                <div className={styles.successTick}><img src={success} alt="success" /></div>
                <div className={styles.successHead}>an email is sent with new password</div>

                <div className={styles.loginRedirectAlign} onClick={() => this.handleRedirect()}>
                    <span className={styles.loginRedirect}>go to login
                <img src={arrow} alt="arrow" className="ml-5" />
                    </span>

                </div>
            </div>
        )
    }
}

export default ForgotPasswordSuccess;