import * as actionTypes from './actionTypes';
import axios from 'axios';

const IDENTITY = process.env.REACT_APP_IDENTITY_API_URL;

//get client list
export const getClientList = () => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.GET_CLIENT_LIST_LOADING
        })
        let apiUrl = IDENTITY+'/client';
        
        axios.get(apiUrl)
            .then(response => {
                if (response.status === 200 || response.status === 201) {
                    dispatch({
                        type: actionTypes.GET_CLIENT_LIST_SUCCESS,
                        clientList: response.data
                    });
                    
                }
            })
            .catch(error => {
                let errMsg  = error;
                if(error.response && error.response.data.errorMessage){
                    errMsg = error.response.data.errorMessage;
                }
                dispatch({
                    type: actionTypes.GET_CLIENT_LIST_ERROR,
                    error: errMsg
                });
            });
    };
}