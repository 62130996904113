import * as actionTypes from './actionTypes';
import axios from 'axios';

const IDENTITY = process.env.REACT_APP_IDENTITY_API_URL;

export const initState = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.INIT_STATE
        })
    };
};


//get users
export const getUsers = (Usertype) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.GET_USERS_LIST_LOADING
        })

        let apiUrl;
        if (Usertype === "THIRD_PARTY") {
            apiUrl = IDENTITY +'/user?userGroup=THIRD_PARTY';
        } else apiUrl = IDENTITY + '/user';


        axios.get(apiUrl)
            .then(response => {
                if (response.status === 200 || response.status === 201) {
                    dispatch({
                        type: actionTypes.GET_USERS_LIST_SUCCESS,
                        users: response.data
                    });
                }
            })
            .catch(error => {
                let errMsg = error;
                if (error.response && error.response.data.errorMessage) {
                    errMsg = error.response.data.errorMessage;
                }
                dispatch({
                    type: actionTypes.GET_USERS_LIST_ERROR,
                    error: errMsg
                });
            });
    };
}

//post users
export const postUsers = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.POST_USER_LOADING
        })
        let apiUrl = IDENTITY+'/user?userGroup=THIRD_PARTY';

        axios.post(apiUrl, data)
            .then(response => {
                if (response.status === 200 || response.status === 201) {
                    dispatch(getUsers("THIRD_PARTY"));
                    dispatch({
                        type: actionTypes.POST_USER_SUCCESS
                    });
                }
            })
            .catch(error => {
                let errMsg = error;
                if (error.response && error.response.data.errorMessage) {
                    errMsg = error.response.data.errorMessage;
                }
                dispatch({
                    type: actionTypes.POST_USER_ERROR,
                    error: errMsg
                });
            });
    };
}

//Get Permissions Action Dispatch
export const getPermissions = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.GET_PERMISSION_LIST_LOADING
        })
        axios.get(IDENTITY+'/permission?channel=THIRD_PARTY')
            .then(response => {
                if (response.status === 200 || response.status === 201) {
                    dispatch({
                        type: actionTypes.GET_PERMISSION_LIST_SUCCESS,
                        permissionData: response.data
                    });
                }
            })
            .catch(error => {
                let errMsg = error;
                dispatch({
                    type: actionTypes.GET_PERMISSION_LIST_ERROR,
                    error: errMsg
                });
            });
    };
};


//Get Policy Action Dispatch
export const getPolicy = (userId, orgId) => {

    let url = IDENTITY+'/policy?userId='+userId+'&orgId='+orgId;

    return dispatch => {
        dispatch({
            type: actionTypes.GET_POLICY_LOADING
        })
        axios.get(url)
            .then(response => {
                if (response.status === 200 || response.status === 201) {
                    dispatch({
                        type: actionTypes.GET_POLICY_SUCCESS,
                        policyData: response.data[0]
                    });
                }
            })
            .catch(error => {
                let errMsg = error;
                dispatch({
                    type: actionTypes.GET_POLICY_ERROR,
                    error: errMsg
                });
            });
    }
}


// put policy data
export const putPolicyData = (payload, policyId) => {
    return dispatch => {
        dispatch({
            type: actionTypes.PUT_POLICY_DATA_LOADING
        })
        axios.put(IDENTITY+`/policy/${policyId}`, payload)
            .then(response => {
                if (response.status === 200 || response.status === 201) {
                    dispatch({
                        type: actionTypes.PUT_POLICY_DATA_SUCCESS,
                        policyData: response.data
                    });
                }
            })
            .catch(error => {
                let errMsg = error;
                if (error.response.data && error.response.data.errorMessage) {
                    errMsg = error.response.data.errorMessage;
                }
                dispatch({
                    type: actionTypes.PUT_POLICY_DATA_ERROR,
                    error: errMsg
                })
            });
    };
};

//Get token
export const getToken = (userId, type) => {

    let url = IDENTITY+'/token?userId=' + userId + '&state=' + type;

    return dispatch => {
        dispatch({
            type: actionTypes.GET_TOKEN_LOADING
        })
        axios.get(url)
            .then(response => {
                if (response.status === 200 || response.status === 201) {
                    dispatch({
                        type: actionTypes.GET_TOKEN_SUCCESS,
                        TokenData: response.data
                    });
                }
            })
            .catch(error => {
                let errMsg = error;
                dispatch({
                    type: actionTypes.GET_TOKEN_ERROR,
                    error: errMsg
                });
            });
    }
}

//Post revoke
export const postRevoke = (data) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.POST_REVOKE_LOADING
        })
        let apiUrl = IDENTITY+'/revoke';
        axios.post(apiUrl, data)
            .then(response => {
                if (response.status === 200 || response.status === 201) {
                    const userId = data.userId;
                    dispatch(getToken(userId, "ACTIVE"));
                    dispatch({
                        type: actionTypes.POST_REVOKE_SUCCESS,
                    });
                }
            })
            .catch(error => {
                let errMsg = error;
                if (error.response && error.response.data.errorMessage) {
                    errMsg = error.response.data.errorMessage;
                }
                dispatch({
                    type: actionTypes.POST_REVOKE_ERROR,
                    error: errMsg
                });
            });
    };
}


//Post generate token
export const generateToken = (data) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.POST_TOKEN_LOADING
        })
        const apiUrl = IDENTITY+'/token';
        axios.post(apiUrl, data)
            .then(response => {
                if (response.status === 200 || response.status === 201) {
                    const userId = data.userId;
                    dispatch(getToken(userId, "ACTIVE"));
                    dispatch({
                        type: actionTypes.POST_TOKEN_SUCCESS,
                    });
                }
            })
            .catch(error => {
                let errMsg = error;
                if (error.response && error.response.data.errorMessage) {
                    errMsg = error.response.data.errorMessage;
                }
                dispatch({
                    type: actionTypes.POST_TOKEN_ERROR,
                    error: errMsg
                });
            });
    };
}

