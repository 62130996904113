import React, { Component } from "react";
import * as actions from "./Store/action";

import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

import LeftNavBar from "../../components/atoms/LeftNavBar/LeftNavBar";
import cx from "classnames";
import styles from "./Users.module.scss";
import Input from '../../components/atoms/Input/Input'
import Modal from '../../components/atoms/Modal/Modal';
import Button from '../../components/atoms/Button/Button';

class Users extends Component {
    state = {
        showModal: false,
        enableSubmit: false,
        formData: {
            orgId: '',
            userName: ''
        },
        type: "THIRD_PARTY"
    }

    componentDidMount() {
        this.props.onGetUsers(this.state.type);
    }

    componentDidUpdate(prevProps, prevState) {
        let submit = false
        if ((this.state.formData !== prevState.formData)) {
            if ((this.state.formData.orgId !== '') && (this.state.formData.userName !== '')) {
                submit = true
            }
            this.setState({
                enableSubmit: submit
            })
        }
        if (prevState.type !== this.state.type) {
            this.props.onGetUsers(this.state.type)
        }
    }

    handleInputChange = (event, inputIdentifier) => {
        let updatedFormData = {
            ...this.state.formData,
        }

        updatedFormData[inputIdentifier] = event.target ? event.target.value : event.value;

        this.setState({
            formData: updatedFormData,
        });
    };

    toggleForm = () => {
        this.setState({
            showModal: !this.state.showModal,
        });
    }

    toggleSave = () => {
        this.setState({
            enableSubmit: false,
            showModal: false
        })
        this.props.onPostUser(this.state.formData)
    }

    handleUsers = (type) => {
        this.setState({
            type: type
        })
    }
    render() {
        return (
            <React.Fragment>
                <Modal handleClose={this.toggleForm} show={this.state.showModal} className={styles.ModalPage} >
                    <div className={cx(styles.ModalForm, styles.scrollbar)}>
                        <form>
                            <div className={cx(this.state.focus ? styles.InputPlace : styles.InputPlaceBlur, "pl-4 my-4")}>
                                <div className='row no-gutters'>
                                    <Input
                                        name="orgId"
                                        type='text'
                                        placeholder="org Id"
                                        value={this.state.formData.orgId}
                                        changed={(event) => this.handleInputChange(event, 'orgId')}
                                        className="col-5 pr-3"
                                        label="org Id"
                                    />
                                    <Input
                                        name="userName"
                                        type='text'
                                        placeholder="user name"
                                        value={this.state.formData.userName}
                                        changed={(event) => this.handleInputChange(event, 'userName')}
                                        className="col-5 pr-3"
                                        label="user name"
                                    />
                                    <div className={styles.alignButton}>
                                        <Button label="save" isDisabled={!this.state.enableSubmit} clickHandler={() => this.toggleSave()} />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal>
                <div className="row px-0">
                    <div className="col-1 px-0">
                        <LeftNavBar />
                    </div>
                    <div className="col-9 px-4 mt-4 py-0">

                        {this.state.type === "THIRD_PARTY" ? <Button label="add new user" clickHandler={() => this.toggleForm(null)} style={{ float: "right" }} ></Button> : null}
                        <ul className="row ml-0 pl-4">
                            <div className={cx("px-4", this.state.type === "THIRD_PARTY" ? styles.TabActive : styles.Tab, styles.TabsHover)} onClick={() => this.handleUsers("THIRD_PARTY")}>THIRD PARTY</div>
                            <div className={cx("px-4", this.state.type === "ALL" ? styles.TabActive : styles.Tab, styles.TabsHover)} onClick={() => this.handleUsers("ALL")}>ALL USERS</div>
                        </ul>


                        <div className={cx(styles.cardScroll,styles.scrollbar, "row")}>
                            {this.props.Users.map((user, index) => {
                                let url = "/users/" + user._id;
                                return (
                                    <div className={"col-6 px-2 pl-3"} key={index}>
                                        <Link to={user.userGroup === "THIRD_PARTY" ? url : "#"} style={{ textDecoration: "none" }}
                                            className={user.userGroup === "THIRD_PARTY" ? styles.userGroupStyle : styles.nonUserGroup}>
                                            <div className={user.userGroup === "THIRD_PARTY" ? styles.userGroupCard : styles.Card}>
                                                {user.userName ? <div className={styles.CardLabel}>{user.userName}</div>
                                                    : <div className={styles.CardLabel}>{user.firstName}&nbsp;{user.lastName}</div>
                                                }
                                                {user.userGroup && this.state.type !== "THIRD_PARTY"?
                                                    <div>
                                                        <span className={cx("mr-3", styles.textMedium)}>user group: {user.userGroup.replace(/_/gi, " ")}</span>
                                                    </div>
                                                    : null
                                                }
                                                {user.userGroup ? null :
                                                    <div>
                                                        <span className={cx("mr-3", styles.textMedium)}>email: {user.email}</span>
                                                        <br />
                                                        <span className={cx("mr-3", styles.textMedium)}>contact: {user.mobileNumber}</span>
                                                    </div>
                                                }
                                                <div><span className={cx("mr-3", styles.textMedium)}>orgId: {user.orgId}</span><br />
                                                    {user.userGroup ? null :
                                                        <React.Fragment>
                                                            <span className={cx("mr-3", styles.textMedium)}>empId: {user.empId}</span>
                                                        </React.Fragment>
                                                    }
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <br />
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        Users: state.user.userList,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        initState: () => dispatch(actions.initState()),
        onGetUsers: (state) => dispatch(actions.getUsers(state)),
        onPostUser: (data) => dispatch(actions.postUsers(data)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Users));
