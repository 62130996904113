import React, { Component } from "react";
import * as actions from "./Store/action";

import { connect } from "react-redux";
import { withRouter } from "react-router";
import LeftNavBar from "../../components/atoms/LeftNavBar/LeftNavBar";
import cx from "classnames";
import styles from "./Activity.module.scss";
import WarningPopUp from "../../components/molecules/WarningPopUp/WarningPopUp";

class Activity extends Component {
    state = {
        type: "ACTIVE",
        showPopUp: false
    }

    componentDidMount() {
        this.props.onGetUsers(this.state.type)
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.state.type !== prevState.type)) {
            this.props.onGetUsers(this.state.type)
        }

        if ((this.props.revokeState !== prevProps.revokeState) && (this.props.revokeState === "SUCCESS")) {
            this.props.onGetUsers(this.state.type)
        }
    }

    handleUserType = (userType) => {
        this.setState({
            type: userType
        })
    }

    handleRevoke = (userId, clientKey) => {
        const revoke = {
            userId: userId,
            clientKey: clientKey
        }
        this.props.onPostRevoke(revoke);
        this.props.onGetUsers(this.state.type)
        this.setState({
            showPopUp: false
        })

    }

    togglePopUp = () => {
        let showDeletePopUpFlag = (this.state.showPopUp ? false : true)
        this.setState({
            showPopUp: showDeletePopUpFlag
        });
    }
    render() {
        return (
            <div className="row px-0">
                <div className="col-1 px-0">
                    <LeftNavBar />
                </div>

                <div className="col-9 px-4 mt-4 py-0">
                    <ul className="row">
                        <div className={cx("px-4", this.state.type === "ACTIVE" ? styles.TabActive : styles.Tab, styles.TabsHover)} onClick={() => this.handleUserType("ACTIVE")}>ACTIVE</div>
                        <div className={cx("px-4", this.state.type === "INACTIVE" ? styles.TabActive : styles.Tab, styles.TabsHover)} onClick={() => this.handleUserType("INACTIVE")}>INACTIVE</div>
                        <div className={cx("px-4", this.state.type === "ALL" ? styles.TabActive : styles.Tab, styles.TabsHover)} onClick={() => this.handleUserType("ALL")}>ALL</div>
                    </ul>

                    <div className={cx(styles.cardScroll,styles.scrollbar)}>
                        {this.props.Users.map((user, index) => {

                            return (
                                <div className={styles.Card} key={index}>
                                    {user.userName ? <div className={styles.CardLabel}>{user.userName}</div>
                                        : <div className={styles.CardLabel}>{user.firstName}&nbsp;{user.lastName}</div>
                                    }
                                    <div>email: <span className={cx("mr-3", styles.textMedium)}>{user.email}</span>contact: <span className={cx("mr-3", styles.textMedium)}>{user.mobileNumber}</span></div>
                                    <div>orgId: <span className={cx("mr-3", styles.textMedium)}>{user.orgId}</span><br />empId: <span className={cx("mr-3", styles.textMedium)}>{user.empId}</span></div>

                                    <div className="row px-0 mx-0">
                                        {user.clients.map((client, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <div className={cx("col-6 pr-3 pl-0")}>
                                                        <div className={styles.clientCard}>
                                                            <div><span className={styles.textLarge}>{client.clientName}</span><br />
                                                                client key: <span className={cx(styles.textMedium)}>{client.clientKey}</span></div>
                                                            <div>client type: <span className={cx(styles.textMedium)}>{client.clientType}</span></div>
                                                            {this.state.type === "ACTIVE" ?
                                                                <div onClick={() => this.togglePopUp()} className="pb-3">
                                                                    {this.state.showPopUp ?
                                                                        <WarningPopUp
                                                                            warningPopUp={() => this.handleRevoke(user.userId, client.clientKey)}
                                                                            closePopup={this.togglePopUp.bind(this)}
                                                                            warning="revoke ?" />
                                                                        : null}

                                                                    <span className={styles.revoke}>REVOKE</span>
                                                                </div>
                                                                : null
                                                            }

                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <br />

            </div>

        )
    }
}

const mapStateToProps = state => {
    return {
        Users: state.activity.activity,
        revokeState: state.user.postRevokeState
    }

};

const mapDispatchToProps = dispatch => {
    return {
        initState: () => dispatch(actions.initState()),
        onGetUsers: (type) => dispatch(actions.getUsers(type)),
        onPostRevoke: (data) => dispatch(actions.postRevoke(data)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Activity));
