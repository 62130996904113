import React, { Component } from "react";

import Input from "../../atoms/Input/Input";
import _ from 'lodash';
import styles from './MultipleInput.module.scss';
import close from '../../../assets/icons/closeNotification.svg';
import tick from '../../../assets/icons/addMore.svg';

class MultipleInput extends Component {
    state={
        URIKey:"",
        URIValue:"",
        URIKeyArr:[],
        URIValueArr:[]
    }

    componentDidUpdate(prevProps){
        if(!_.isEqual(this.props.UriObj, prevProps.UriObj))
        {
            let updatedUriArrValue  = [];
            let updatedUriArrKey = [] ;
            _.forEach(this.props.UriObj , function(value,key){

                updatedUriArrValue.push(value);
                updatedUriArrKey.push(key) ;
            })
            this.setState({
                URIValueArr : updatedUriArrValue,
                URIKeyArr:updatedUriArrKey,
                URIKey: "",
                URIValue: ""
            })
        }
        if(!_.isEqual(this.props.URIKeyArr,prevProps.URIKeyArr)){
            this.setState({
                URIKeyArr :this.props.URIKeyArr
            })
        }
        if(!_.isEqual(this.props.URIValueArr,prevProps.URIValueArr)){
            this.setState({
                URIValueArr :this.props.URIValueArr
            })
        }
    }

    handleInputChange = (event, inputIdentifier) => {
        let updatedDataKey = {
            ...this.state.URIKey,
        }
        let updatedDataValue = {
            ...this.state.URIValue,
        }
        if(inputIdentifier === 'key')
        {
            updatedDataKey = event.target ? event.target.value : event.value;
            this.setState({
                URIKey: updatedDataKey,
            });
        }
        else if (inputIdentifier === 'value')
        {
            updatedDataValue = event.target ? event.target.value : event.value;
            this.setState({
                URIValue: updatedDataValue,
            });
        }
        
    };

    handleAddUri=(event)=>{
        event.preventDefault();
        let URIKey = this.state.URIKey;
        let URIValue = this.state.URIValue;
        let UriArrKey = [...this.state.URIKeyArr];
        let UriArrValue = [...this.state.URIValueArr];
        UriArrKey.push(URIKey);
        UriArrValue.push(URIValue);
        this.setState({
            URIKey : "",
            URIValue : "",
            URIKeyArr : UriArrKey,
            URIValueArr : UriArrValue
        })
        this.props.changed(URIKey,URIValue,'add');
    }

    deleteArrItem=(uriKey,uriValue)=>{
        let updatedUriArrKey = _.cloneDeep(this.state.URIKeyArr);
        let updatedUriArrValue = _.cloneDeep(this.state.URIValueArr);
        let removeValue = updatedUriArrValue.filter(function(value, index){
            return value !== uriKey
        })
        let removeKey = updatedUriArrKey.filter(function(value, index){
            if(_.isEqual(value,uriValue)) return null;
            return value !== uriValue;
        })
        this.setState({
            URIValueArr: removeValue,
            URIKeyArr : removeKey
        })
        this.props.changed(uriKey,uriValue,'delete');
    }

    render(){
        return(
            <React.Fragment>
            <div className="row no-gutters">
            
            <Input
                name="key"
                className="col-4 pl-1 mr-0 pr-3"
                label = "Params Key"
                type ="text"
                placeholder="Params Key"
                changed={(event) => this.handleInputChange(event, 'key')}
                value={this.state.URIKey}
            />
            <Input
                name="value"
                className="col-4 pl-0 pr-3"
                label = "Params Value"
                type ="text"
                placeholder="Params Value"
                changed={(event) => this.handleInputChange(event, 'value')}
                value={this.state.URIValue}
            />
            {this.state.URIKey.length !== 0  && this.state.URIValue.length !== 0 ? 
            <span onClick={(event)=>this.handleAddUri(event)} className={styles.rightTick}>
                <img src={tick} alt="tick"/>
            </span>
            : null }
            </div>

            
            
            <div className="ml-2">
            {this.state.URIValueArr.map((uri,index)=>{
                return(
                    <div className={styles.option} key={index}>
                    <div key={index} >
                    {this.state.URIKeyArr[index]}
                    &nbsp;: &nbsp;
                    <span className={styles.multipleText}>{uri}</span>
                    
                    <span className={styles.alignClose} onClick={()=>this.deleteArrItem(this.state.URIKeyArr[index],uri)}>
                        <img src={close} alt="img" style={{cursor:"pointer"}}/></span>
                        </div>
                    </div>
                )
            })}
            </div>
        
        </React.Fragment>
        )
    }
}

export default MultipleInput;
