import * as actionTypes from './actionTypes';
import { updateObject } from './utility';

const initialState = {
    postForgotPasswordState: 'INIT',
    forgotPassword: {},
}

//INIT STATE REDUCER
const initState = () => {
    return initialState;
}

//FORGOT PASSWORD STATE
const postForgotPasswordLoading = (state) => {
    return updateObject(state, {
        postForgotPasswordState: 'LOADING'
    });
}

const postForgotPasswordSuccess = (state, action) => {
    return updateObject(state, {
        postForgotPasswordState:'SUCCESS',
        forgotPassword: action.forgotPassword,
        error: null
    });
};

const postForgotPasswordError = (state, action) => {
    return updateObject(state, {
        postForgotPasswordState: 'ERROR',
        error: action.error,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INIT_STATE: return initState();

        case actionTypes.POST_FORGOT_PASSWORD_LOADING: return postForgotPasswordLoading(state);
        case actionTypes.POST_FORGOT_PASSWORD_SUCCESS: return postForgotPasswordSuccess(state, action);
        case actionTypes.POST_FORGOT_PASSWORD_ERROR: return postForgotPasswordError(state, action);

        default: return state;
    }
};

export default reducer;

