import * as actionTypes from './actionTypes';
import { updateObject } from '../../ClientMgmtStore/utility';

const initialState = {
    getClientListState: 'INIT',
    clientList: [],
}

//INIT STATE REDUCER
const initState = () => {
    return initialState;
}

//GET NOTIFICATION LIST
const getClientListLoading = (state) => {
    return updateObject(state, {
        getClientListState: 'LOADING'
    });
}

const getClientListSuccess = (state, action) => {
    return updateObject(state, {
        getClientListState:'SUCCESS',
        clientList: action.clientList,
    });
};

const getClientListError = (state, action) => {
    return updateObject(state, {
        getClientListState: 'ERROR',
        error: action.error,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INIT_STATE: return initState();

        case actionTypes.GET_CLIENT_LIST_LOADING: return getClientListLoading(state);
        case actionTypes.GET_CLIENT_LIST_SUCCESS: return getClientListSuccess(state, action);
        case actionTypes.GET_CLIENT_LIST_ERROR: return getClientListError(state, action);

        default: return state;
    }
};

export default reducer;

