import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import LogoIcon from "./LogoIcon";
import styles from './LeftNavBar.module.scss';
import bplogo from "../../../assets/icons/betterPlace_logo.svg";
import { AuthConsumer } from '../../../services/authContext';

class LeftNavBar extends Component {
  render() {
    return (
      <div className={styles.leftNav}>
        <div className={styles.logoMarginBottom}>

          <NavLink to="/">
            <LogoIcon url={bplogo} alt="BetterPlace Logo" className={styles.size} />
          </NavLink>

        </div>
        <div className={styles.leftNavAlign}>
          <div className="py-3">
            <NavLink to="/clients"
              className={styles.NavLinkfont}
              activeClassName={styles.NavLinkfontActive}
              exact>
              all clients
          </NavLink>
          </div>
          <div className="py-3">
            <NavLink to="/resource"
              className={styles.NavLinkfont}
              activeClassName={styles.NavLinkfontActive}
              exact>
              resources
          </NavLink>
          </div>
          {/* <div className="py-3">
            <NavLink to="/users"
              className={styles.NavLinkfont}
              activeClassName={styles.NavLinkfontActive}
              exact>
              users
          </NavLink>
          </div>
          <div className="py-3">
            <NavLink to="/activity"
              className={styles.NavLinkfont}
              activeClassName={styles.NavLinkfontActive}
              exact>
              activity
          </NavLink>
          </div> */}
          <br />
          <div >
            <AuthConsumer >
              {
                ({ logout }) => {
                  return (
                    <div className={styles.NavLinkfont} onClick={() => logout()}>
                      logout
                  </div>
                  )
                }
              }
            </AuthConsumer>
          </div>
        </div>


      </div>

    );
  }
}

export default LeftNavBar;
